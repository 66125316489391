import API from '../plugins/axios';

const GET_All_COUNTRIES_REQUEST = 'GET_All_COUNTRIES_REQUEST';
const GET_All_COUNTRIES_SUCCESS = 'GET_All_COUNTRIES_SUCCESS';
const GET_All_CITIES_REQUEST = 'GET_All_CITIES_REQUEST';
const GET_All_CITIES_SUCCESS = 'GET_All_CITIES_SUCCESS';
const GET_All_SCHOOLS_REQUEST = 'GET_All_SCHOOLS_REQUEST';
const GET_All_SCHOOLS_SUCCESS = 'GET_All_SCHOOLS_SUCCESS';
const GET_All_UNIVERSITIES_REQUEST = 'GET_All_UNIVERSITIES_REQUEST';
const GET_All_UNIVERSITIES_SUCCESS = 'GET_All_UNIVERSITIES_SUCCESS';
const GET_All_WORKS_REQUEST = 'GET_All_WORKS_REQUEST';
const GET_All_WORKS_SUCCESS = 'GET_All_WORKS_SUCCESS';
const GET_All_AGE_AND_RELATION_REQUEST = 'GET_All_AGE_AND_RELATION_REQUEST';
const GET_All_AGE_AND_RELATION_SUCCESS = 'GET_All_AGE_AND_RELATION_SUCCESS';
const SAVE_FILTER_IN_BACKEND_REQUEST = 'SAVE_FILTER_IN_BACKEND_REQUEST';
const SAVE_FILTER_IN_BACKEND_SUCCESS = 'SAVE_FILTER_IN_BACKEND_SUCCESS';
const GET_USER_FILTER_REQUEST = 'GET_USER_FILTER_REQUEST';
const GET_USER_FILTER_SUCCESS = 'GET_USER_FILTER_SUCCESS';
const RESET_FILTER_REQUEST = 'RESET_FILTER_REQUEST';
const RESET_FILTER_SUCCESS = 'RESET_FILTER_SUCCESS';
/**
 * Экшн для получения списка всех стран
 * @returns {Function}
 */
export function getAllCountries() {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_All_COUNTRIES_REQUEST
        });
        let sign = getState().user.sign;
        await API({
            url:'/filter/get-all-countries',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            /**
             * Переименуем с API
             * [
             *  'id' => 1
             *  'title' => Россия
             * ]
             * в
             * [
             *  'value' => 1
             *  'label' => Россия
             * ]
             * Для select
             */
            let countries = [];
            response.data.map( country => {
                return countries.push({
                    'value': country.id,
                    'label': country.title
                });
            });

            dispatch({
                type: GET_All_COUNTRIES_SUCCESS,
                payload: countries,
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Экшн для получения списка всех городов
 * @returns {Function}
 */
export function getAllCities(countryId) {
    return (dispatch, getState) => {
        dispatch({
            type: GET_All_CITIES_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/filter/get-all-cities',
            method: 'post',
            data: {
              'countryId': countryId,
                'sign': sign
            },
        }).then(function (response) {
            /**
             * Переименуем с API
             * [
             *  'id' => 1
             *  'title' => Новосиб
             * ]
             * в
             * [
             *  'value' => 1
             *  'label' => Новосиб
             * ]
             * Для select
             */

            let cities = [];
            response.data.map( city => {
                return cities.push({
                    'value': city.id,
                    'label': city.title
                });
            });

            dispatch({
                type: GET_All_CITIES_SUCCESS,
                payload: cities,
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Экшн для получения списка всех школ
 * @returns {Function}
 */
export function getAllSchools() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_All_SCHOOLS_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/filter/get-all-schools',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            /**
             * Переименуем с API
             * [
             *  'id' => 1
             *  'title' => Школа 1
             * ]
             * в
             * [
             *  'value' => 1
             *  'label' => Школа 1
             * ]
             * Для select
             */

            let schools = [];
            response.data.map( school => {
                return schools.push({
                    'value': school.id,
                    'label': school.title
                });
            });

            dispatch({
                type: GET_All_SCHOOLS_SUCCESS,
                payload: schools,
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Экшн для получения списка всех университетов
 * @returns {Function}
 */
export function getAllUniversities() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_All_UNIVERSITIES_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/filter/get-all-universities',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            /**
             * Переименуем с API
             * [
             *  'id' => 1
             *  'title' => СГУПС
             * ]
             * в
             * [
             *  'value' => 1
             *  'label' => СГУПС
             * ]
             * Для select
             */

            let universities = [];
            response.data.map( university => {
                return universities.push({
                    'value': university.id,
                    'label': university.title
                });
            });

            dispatch({
                type: GET_All_UNIVERSITIES_SUCCESS,
                payload: universities,
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Экшн для получения списка всех работ
 * @returns {Function}
 */
export function getAllWorks() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_All_WORKS_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/filter/get-all-works',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            /**
             * Переименуем из
             * response.data =
             *  [
             *      'id'    =>  1,
             *      'where_work_user_id' => [
             *              'id'    =>  9,
             *              'title' => 'Команда Вконтакте'
             *      ],
             *      'position' => Генеральный директор
             *  ]
             * в
             * [
             *      companies =>
             *          [
             *              'value' => 9
             *              'label' => Команда Вконтакте
             *          ],
             *      positions =>
             *          [
             *              'value' => 1
             *              'label' => Генеральный директор
             *          ],
             * ]
             * Для select
             */

            let companies = [];
            let positions = [];
            response.data.map( item => {
                return companies.push({
                    'value': item.whereWorkUser.id,
                    'label': item.whereWorkUser.title
                });
            });
            response.data.map( item => {
                return positions.push({
                    'value': item.id,
                    'label': item.position
                });
            });
            dispatch({
                type: GET_All_WORKS_SUCCESS,
                payload: {
                    "positions" : positions,
                    "companies": companies
                }

            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Экшн для получения списка всех возраста и отношений
 * @returns {Function}
 */
export function getAgeAndRelation() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_All_AGE_AND_RELATION_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/filter/get-age-and-relation',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            /**
             * Переименуем с API
             * [
             *  'minAge' => 34,
             *  'maxAge' => 34,
             *  'relation' => [
             *          'relation' => 0
             *      ]
             * ]
             * в
             * [
             *  'minAge' => [
             *              'value' => 1
             *              'label' => 34
             *           ],
             *  'maxAge' => [
             *              'value' => 1
             *              'label' => 34
             *           ],
             *  'relation' => [
             *              'value' => 0
             *              'label' => 'Замужем'
             *           ],
             * ]
             * Для select
             */

            let minAge = response.data.minAge;
            let maxAge = response.data.maxAge;
            let age = [];
            let j = 0;
            for(let i=minAge; i<=maxAge; i++) {
                age.push({
                    "value":j,
                    "label":i
                });
                j++;
            }
            let relations=[];
            let relationLabel ='';
            response.data.relation.map( item => {
                switch (item.relation) {
                    case 1:
                        relationLabel = 'не женат/не замужем';
                        break;
                    case 2:
                        relationLabel = 'есть друг/есть подруга';
                        break;
                    case 3:
                        relationLabel = 'помолвлен/помолвлена';
                        break;
                    case 4:
                        relationLabel = 'женат/замужем';
                        break;
                    case 5:
                        relationLabel = 'всё сложно';
                        break;
                    case 6:
                        relationLabel = 'в активном поиске';
                        break;
                    case 7:
                        relationLabel = 'влюблён/влюблена';
                        break;
                    case 8:
                        relationLabel = 'в гражданском браке';
                        break;
                    default:
                        relationLabel = 'не указано';
                        break
                }
                return relations.push({
                    "value":item.relation,
                    "label":relationLabel
                })
            });

            dispatch({
                type: GET_All_AGE_AND_RELATION_SUCCESS,
                payload: {
                    "minAges": age,
                    "maxAges": age,
                    "relations": relations
                },
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Сохранение фильтров выбранных пользователем в redis
 * @param country
 * @param city
 * @param school
 * @param university
 * @param company
 * @param position
 * @param relation
 * @param isMale
 * @param minAge
 * @param maxAge
 * @returns {Function}
 */
export function saveFilter(
    country,
    city,
    school,
    university,
    company,
    position,
    relation,
    isMale,
    minAge,
    maxAge
) {
    return (dispatch,getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: SAVE_FILTER_IN_BACKEND_REQUEST,
        });
        API({
            url:'/filter/save-filter',
            method: 'post',
            data: {
                'sign': sign,
                'country': country,
                'city': city,
                'school': school,
                'university': university,
                'company': company,
                'position': position,
                'relation': relation,
                'isMale': isMale,
                'minAge': minAge,
                'maxAge': maxAge,
            },
        }).then(function (response) {
            dispatch(getCurrentUserFilter());
            dispatch({
                type: SAVE_FILTER_IN_BACKEND_SUCCESS,
                payload: response.data,
            });
        })
            .catch(function (error) {
                console.log(error)
            });

    }
}

export function getCurrentUserFilter() {
    return async (dispatch,getState) => {
        await dispatch({
            type: GET_USER_FILTER_REQUEST,
        });
        let sign = getState().user.sign;
        await API({
            url:'/filter/get-filter-from-redis',
            method: 'post',
            data: {
                'sign': sign
            }
        }).then(function (response) {
            dispatch({
                type: GET_USER_FILTER_SUCCESS,
                payload: response.data.filters
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

export function getFilterData() {
    return async dispatch => {
        await dispatch(getAllCountries());
        // await dispatch(getAllSchools());
        // await dispatch(getAllUniversities());
        // await dispatch(getAllWorks());
        await dispatch(getAgeAndRelation());
    }
}

export function resetFilter() {
    return async (dispatch, getState) => {
        let sign = getState().user.sign;
         dispatch({
            type: RESET_FILTER_REQUEST,
        });
        await API({
            url:'/filter/reset-filter',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function () {
            dispatch(getCurrentUserFilter());
            dispatch({
                type: RESET_FILTER_SUCCESS,
            });
        }).then(function (error) {
            dispatch({
                type: RESET_FILTER_SUCCESS,
            });
            });
    }
}