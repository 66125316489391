import React  from 'react';
import PropTypes from 'prop-types';

export default function IconProfile(props) {
    return(
        <svg width={props.width} height={props.height} viewBox="0 0 30 30" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M24.126 21.8093C24.126 22.5176 23.8309 23.1669 23.3587 23.6391C22.8865 24.1112 22.2372 24.4064 21.529 24.4064H17.6039V26H21.5585C22.7094 25.9705 23.7424 25.4983 24.5097 24.731C25.277 23.9637 25.7197 22.9308 25.7197 21.7798V17.5891H24.126V21.8093Z" fill={props.fill} stroke={props.fill} strokeWidth="0.5"/>
        <path d="M25.7197 4.16118C25.7197 3.01021 25.2475 1.9773 24.5097 1.20999C23.7424 0.442678 22.7094 0 21.5585 0H17.6039V1.59364H21.5585C22.2668 1.59364 22.916 1.88876 23.3882 2.36095C23.8604 2.83314 24.1555 3.4824 24.1555 4.19069V8.41089H25.7492V4.16118H25.7197Z" fill={props.fill} stroke={props.fill} strokeWidth="0.5"/>
        <path d="M2.61181 23.6095C2.13962 23.1374 1.8445 22.4881 1.8445 21.7798V17.5891H0.250854V21.8093C0.250854 22.9603 0.723045 23.9932 1.46084 24.7605C2.25766 25.4983 3.29058 25.9705 4.44155 25.9705H8.39614V24.3769H4.44155C3.73326 24.3769 3.084 24.0817 2.61181 23.6095Z" fill={props.fill} stroke={props.fill} strokeWidth="0.5"/>
        <path d="M1.87404 4.16118C1.87404 3.45289 2.16916 2.80363 2.64135 2.33144C3.11354 1.85925 3.7628 1.56413 4.47109 1.56413H8.42568V0H4.44158C3.29061 0 2.25769 0.472191 1.49038 1.20999C0.723074 1.9773 0.280396 3.01022 0.280396 4.16118V8.38138H1.87404V4.16118Z" fill={props.fill} stroke={props.fill} strokeWidth="0.5"/>
        <path d="M18.5483 19.714C18.991 19.714 19.3451 19.3598 19.3451 18.9171C19.3451 17.1759 18.6368 15.5823 17.4859 14.4313C16.9546 13.9001 16.3054 13.4574 15.6266 13.1623C15.6856 13.1033 15.7446 13.0738 15.8037 13.0148C16.512 12.3065 16.9546 11.3031 16.9546 10.2111C16.9546 9.11919 16.512 8.11578 15.8037 7.4075C15.0954 6.69921 14.092 6.25653 13 6.25653C11.9081 6.25653 10.9047 6.69921 10.1964 7.4075C9.48812 8.11578 9.04544 9.11919 9.04544 10.2111C9.04544 11.3031 9.48812 12.3065 10.1964 13.0148C10.2554 13.0738 10.3145 13.1033 10.3735 13.1623C9.66519 13.487 9.04544 13.9001 8.51422 14.4313C7.36326 15.5823 6.65497 17.1759 6.65497 18.9171C6.65497 19.3598 7.00911 19.714 7.45179 19.714C7.89447 19.714 8.24861 19.3598 8.24861 18.9171C8.24861 17.6186 8.77983 16.4086 9.63568 15.5528C10.4915 14.6969 11.7015 14.1657 13 14.1657C14.2986 14.1657 15.5086 14.6969 16.3644 15.5528C17.2202 16.4086 17.7515 17.5891 17.7515 18.9171C17.7515 19.3598 18.1056 19.714 18.5483 19.714ZM14.6822 11.8933C14.2395 12.336 13.6493 12.6016 13 12.6016C12.3508 12.6016 11.7605 12.336 11.3179 11.8933C10.8752 11.4506 10.6096 10.8604 10.6096 10.2111C10.6096 9.56187 10.8752 8.97163 11.3179 8.52895C11.7605 8.08627 12.3508 7.82066 13 7.82066C13.6493 7.82066 14.2395 8.08627 14.6822 8.52895C15.1249 8.97163 15.3905 9.56187 15.3905 10.2111C15.3905 10.8604 15.1249 11.4506 14.6822 11.8933Z" fill={props.fill} stroke={props.fill} strokeWidth="0.5"/>
        </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={props.width} height={props.height} fill={props.fill}/>
                </clipPath>
            </defs>
        </svg>
    );
}

IconProfile.propTypes  = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    fill: PropTypes.string.isRequired,
}