import React from 'react';
import './Switch.css';

class Switch extends React.Component {

    render () {
        return(
            <div className="SwitchWrapper">
                <div className="SwitchContainer">
                    <label>
                        <input ref="Switch"
                               checked={ this.props.isChecked }
                               onChange={ this.props.action }
                               className="Switch"
                               type="checkbox"
                        />
                        <div>
                            <div></div>
                        </div>
                    </label>
                </div>
            </div>
        );
    }
}

export default Switch;