import React, { Component } from 'react'
import { Switch, Route } from "react-router-dom"
import Start from './../pages/Start'
import StartInfo from './../pages/StartInfo'
import SetSex from './../pages/SetSex'
import SetAge from './../pages/SetAge'
import Rating from '../pages/Rating'
import CabinetRouter from './CabinetRouter'
import Tops from './../pages/Tops'
import Filter from './../pages/Filter'
import Profile from './../pages/Profile'
import ProfileId from './../pages/ProfileId'
import LavkaLikes from "../pages/LavkaLikes"
import SameFooterRouter from "./SameFooterRouter"
import Premium from './../pages/Premium'
import Settings from "../pages/Settings"
import Presents from "../pages/Presents"
import SendPresent from "../pages/SendPresent"
import AllsLikes from './../pages/AllsLikes'
import ILike from './../pages/ILike'
import YouLike from './../pages/YouLike'
import MyFavorites from './../pages/MyFavorites'
import SaveGenderAndAge from "../pages/SaveGenderAndAge";


class AppRouter extends Component {

    render() {
        const {gender} = this.props.user.userFromBackend;
        return (
                <>
                    <Switch>
                        <Route path="/start" component={Start} />
                        <Route path="/start-info/" component={StartInfo} />
                        <Route path="/set-sex/" component={SetSex} />
                        <Route path="/set-age/" component={SetAge} />
                        <Route path="/save-gender-and-age/" component={SaveGenderAndAge} />
                        <CabinetRouter path="/rating/:slug" component={Rating} />
                        <CabinetRouter name="tops" path="/tops/:slug/:gender" component={Tops} />
                        <SameFooterRouter path="/filter/" name="Фильтр по топу" component={Filter} />
                        <SameFooterRouter path="/lavka-likes/" name="Лавка лайков" component={LavkaLikes} />
                        <SameFooterRouter path="/premium/" name="Премиум" component={Premium} />
                        <SameFooterRouter path="/settings/" name="Настройки" component={Settings} />
                        <SameFooterRouter path="/alls-likes/" name="Взаимные симпатии" component={AllsLikes} />
                        <SameFooterRouter path="/i-like/" name="Вы лайкнули" component={ILike} />
                        <SameFooterRouter path="/you-like/" name="Вас лайкнули" component={YouLike} />
                        <SameFooterRouter path="/my-favorites/"
                                          name={"Фаворит" + (gender === 2 ? "ки" : "ы")}
                                          component={MyFavorites} />
                        <SameFooterRouter path="/presents/:slug" name="Подарки" component={Presents} />
                        <SameFooterRouter path="/send-present/" name="Подарить" component={SendPresent} />
                        <CabinetRouter path="/profile-rating/" component={Profile} />
                        <CabinetRouter path="/:id/profile-rating/" component={ProfileId} />
                        <CabinetRouter exact path="/:id" component={ProfileId} />
                        <CabinetRouter exact path="/" component={Profile} />
                    </Switch>
                </>
        );
    }
}

export default AppRouter