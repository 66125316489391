import React, {Component} from 'react';
import {getYouLike, updateYouLike} from "../actions/PhotosActions";
import { connect } from 'react-redux'
import { Spinner } from '@vkontakte/vkui';
import ImageItem from "../components/ImageItem";

class YouLike extends Component {
    constructor(props) {
        super(props);
        this.state = {
            youLike: null,
            offset: 5
        };
    }

    componentDidMount() {
        this.props.getYouLikeAction(0);
        window.scrollTo(0,0);

        setTimeout(() => {
            this.setState({
                youLike: this.props.photos.youLike
            });
        }, 1000);
    }

    handlerscrollToRefresh = () => {
        const {offset} = this.state;
        this.props.updateYouLikeAction(offset)
            .then( (response) => {
                if (response) {
                    this.setState({
                        youLike: this.state.youLike.concat(response.data)
                    });
                }
            })
            .then( () => {
                this.setState(prevState => ({
                    offset: prevState.offset + 5,
                }));
            })
            .catch( (error) => {
                console.log(error)
            });

    };

    render() {
        if (this.props.photos.isLoading === true ||
            this.props.user.isLoading === true ||
            this.state.youLike === null
        ) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }else {
            const {youLike} = this.props.photos;
            return (
                <div className={youLike.length === 1 || youLike.length === 0 ? "Fixed" : "WrapperPremium"}
                >
                    <div className="PaddingToTop PaddingToBottom">
                        {
                            youLike.length !== 0 ? <ImageItem
                                    items={youLike}
                                    scrollToRefresh={() => this.handlerscrollToRefresh} /> :
                                <h3>Вас ещё никто не лайкал</h3>
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getYouLikeAction: (offset) => {
            dispatch(getYouLike(offset))
        },
        updateYouLikeAction: (offset) => {
            return dispatch(updateYouLike(offset))
        },
    }
};

const mapStateToProps = store => {
    return {
        photos: store.photos,
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YouLike)