import React from 'react';
import './StartInfo.css';
import Title from './../components/Title';
import SubTitle from './../components/SubTitle';
import LargeButton from './../components/LargeButton';
import { connect } from 'react-redux'
import {setAge, setGender, setUser} from "../actions/UserActions";

class StartInfo extends React.Component {

    handlerGoToRoot = () => {
        this.props.history.replace('/save-gender-and-age');
    };

    render() {
        const {photo_100} = this.props.user.ownUserProfile;
        var urlAvatar = {
            'background': 'url("' + photo_100 + '")',
            'backgroundSize': 'cover',
        };
        return (
            <div className="StartInfo">
                <div className="Avatar" style={urlAvatar}>
                </div>
                <Title name="В сервисе “ТОПовые”"
                font={18} weight={100}/>
                <Title name="у всех пользователей"
                       font={18} weight={100}/>
                <Title name="ограниченное количество лайков"
                       font={18} weight={100}/>
               <div className="StartInfo__SubTitleGroup">
                   <SubTitle name="Лайки достанутся только"/>
                   <SubTitle name="самым топовым!"/>
               </div>
                <LargeButton name="Получить 10 лайков"
                             action={this.handlerGoToRoot}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserAction: () => {
            dispatch(setUser())
        },
        setAgeAction: (age) => {
            dispatch(setAge(age))
        },
        setGenderAction: (sex) => {
            dispatch(setGender(sex))
        },
    }
};

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StartInfo)