import React from 'react';
import './FavotityBar.css';

function FavotityBar(props) {
    const FavorityBarCount = () => {
       if(props.count > 0) {
           return <span>{ props.count }</span>;
    }else {
           return<span></span>;
       }};
    return (
            <div className="FavotityBarWrapper" onClick={props.action}>
                <div className="FavotityBar">
                    <span className="FavotityBarName">{ props.name }</span>
                    <div className="FavotityBarSquare">
                        <FavorityBarCount/>
                    </div>
                </div>
            </div>
    );
}

export default FavotityBar;