import React, {Component} from 'react';
import './MenuTop.css';
import filter from './../assets/icon/filter.svg';

class MenuTops extends Component {
    replaceTo = (url) => {
        this.props.history.replace(url);
    };

    pushTo = (url) => {
        this.props.history.push(url);
    };

    render() {
        const {slug, gender} = this.props;
        let topsDayLink={};
        let topsWeekLink={};
        let topsMonthLink={};
        const activeLink = {
            'color': 'white',
            'borderBottomColor': 'white'
        };
        const passiveLink = {
            'color': '#B3A7B6',
            'borderBottomColor': '#5A465E'
        };
        switch (slug) {
            case 'week':
                topsDayLink = passiveLink;
                topsWeekLink = activeLink;
                topsMonthLink = passiveLink;
                break;
            case 'month':
                topsDayLink = passiveLink;
                topsWeekLink = passiveLink;
                topsMonthLink = activeLink;
                break;
            default:
                topsDayLink = activeLink;
                topsWeekLink = passiveLink;
                topsMonthLink = passiveLink;
        }

        return (
            <div className="TopNavbar NavPadding MenuTop">
                <div onClick={() => this.replaceTo('/tops/day/' + gender)} className="TopLinks" style={topsDayLink}>
                    ТОП дня
                </div>
                <div onClick={() => this.replaceTo('/tops/week/' + gender)} className="TopLinks" style={topsWeekLink}>
                    ТОП недели
                </div>
                <div onClick={() => this.replaceTo('/tops/month/' + gender)} className="TopLinks" style={topsMonthLink}>
                    ТОП месяца
                </div>
                <div onClick={() => this.pushTo('/filter')} >
                    <img src={filter} alt="Иконка фильтра"/>
                </div>
            </div>
        );
    }
}

export default MenuTops;