import 'core-js/features/map';
import 'core-js/features/set';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { store, history } from './store/configureStore'
import App from './layouts/app/App'
import * as serviceWorker from './serviceWorker'
import * as VKConnect from '@vkontakte/vkui-connect'
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper';
import { ConnectedRouter } from 'connected-react-router'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import {isMobile} from "react-device-detect";

VKConnect.send('VKWebAppInit', {});

// Корневой элемент приложения
const root = document.getElementById('root');

// Вызываем хелпер, передавая в аргумент корневой элемент приложения
mVKMiniAppsScrollHelper(root);

let renderApp = <Provider store={store}>
    <ConnectedRouter history={history}>
        <App />
    </ConnectedRouter>
</Provider>;
        
if (isMobile) {
    renderApp =<DeviceOrientation lockOrientation={'portrait'}>
        {/* Will only be in DOM in landscape */}
        <Orientation orientation='portrait' alwaysRender={false}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </Orientation>
        <Orientation orientation='landscape' alwaysRender={false}>
            <h1 style={{textAlign: 'center', paddingTop: '10%', color: 'white'}}>
                Пожалуйста, переверните устройство
            </h1>
        </Orientation>
    </DeviceOrientation>
}

// Рендер приложения
ReactDOM.render(
    renderApp
    ,
    root
)

serviceWorker.unregister();
