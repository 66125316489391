import React from 'react';
import './Profile.css';
import vk from './../assets/icon/vk.svg'
import SmallHeart from "../assets/icon/small-heart.svg";
import LargeButton from './../components/LargeButton';
import Title from './../components/Title';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { getOtherUserPhotosFromBackend } from "../actions/PhotosActions";
import { Spinner } from '@vkontakte/vkui';
import {getUserById,getFavoriteStatus,setFavoriteStatus} from "../actions/UserActions";
import NeedPremiumStatus from "../components/modals/NeedPremiumStatus";
import RepostForIphone from "../components/modals/RepostForIphone";
import LazyLoad from 'react-lazy-load';
import FillSmallHeart from "./../assets/icon/fill-small-heart.svg";
import pluralize from "./../plugins/pluralize";
import {isIOS} from 'react-device-detect';
import {saveCurrentPhoto} from "../actions/PhotosActions";

const UserProfilePhotos = (props) => {
    const {userProfilePhotos} = props;

    return (
        userProfilePhotos.map( (userProfilePhoto) => {
            return (
                <div className="WrapperItem" key={userProfilePhoto.id}>
                    <div className="NameTopUser">
                    <span className="ProfileWrapperText">
                        <span className="ProfileText"></span>
                    </span>
                        <div className="WrapperHeart">
                            <div className="HeartCounter">
                                <img src={ userProfilePhoto.likesCount !== 0 ? SmallHeart : FillSmallHeart } alt="Сердце"/>
                            </div>
                            <div className="Count">
                                {userProfilePhoto.likesCount}
                            </div>
                        </div>
                    </div>
                    <LazyLoad offsetVertical={300} >
                        <img className="TopImage TopPhotos__img"
                             height={userProfilePhoto.height}
                             width={userProfilePhoto.width}
                             src={userProfilePhoto.url} alt=""/>
                    </LazyLoad>
                </div>
            );
        })
        );
}

class ProfileId extends React.Component {

    state = {
        needPremiumStatus: false,
        repostForIphone: false,
        disbled: false
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getUserByIdAction(params.id);
        this.props.getOtherUserPhotosFromBackendAction(params.id);
        this.props.getFavoriteStatusAction(params.id);

        if (this.props.location.state !== undefined) {
            if (this.props.location.state.photoId !== undefined) {
                this.props.saveCurrentPhotoAction(this.props.location.state.photoId);
            }
        }

        window.scrollTo(0,0);
    }

    handlerCloseNeedPremiumStatus = () => {
        this.setState({needPremiumStatus : false})
    };

    handlerCloseRepostForIphone = () => {
        this.setState({repostForIphone : false})
    };

     handlerSetFavorite = () => {
         const { match: { params } } = this.props;
         this.setState({disabled: true})
         setTimeout(() => {
             this.props.setFavoriteStatusAction(params.id);
             this.setState({disabled: false})
         }, 500)
    };

    handlerOpenNeedPremiumStatus = () => {
        this.setState({needPremiumStatus : true})
    };

    handlerOpenRepostForIphone = () => {
        this.setState({repostForIphone: true})
    }

    render() {
        if (this.props.user.anotherUserProfile === null) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }
        
        const { id, photo_100, first_name, city, sex, age } = this.props.user.anotherUserProfile;
        const {favoriteStatus} = this.props.user;
        const { userProfilePhotos } = this.props.photos;
        const { needPremiumStatus, repostForIphone, disabled } = this.state;

        let cityName;
        if(city instanceof Object ) {
            cityName = city.title;
        }
        let pressButtonStyle ={
            'backgroundColor' : 'transparent',
            'color': '#2EAE62'
        };
        console.log(userProfilePhotos)
        return (
            <div className="PaddingToTop PaddingToBottom">
                <div className="ProfileDataIfAnonimus">
                    <div className="ProfileId__MiddleAvatar">
                        <img src={photo_100} alt="Аватарка пользователя"/>
                    </div>
                    <div className="ProfileWrapperUserDataIfAnonimus">
                        <div className="ProfileNameUser">
                            { first_name }
                        </div>
                        <div className="ProfileAgeCityUser">
                            {
                                (age !== null && age !== undefined) ?
                                    age + ' ' + pluralize(age, ['год', 'года', 'лет']): ''
                            }
                            {
                                age !== null &&
                                age !== undefined &&
                                cityName !== null &&
                                cityName !== undefined &&
                                cityName !== ''
                                    ? ', ': ''
                            }

                            { cityName !== undefined &&
                            cityName !== null &&
                            cityName !== ''
                                ? `г. ` + cityName : '' }
                        </div>
                        {
                            this.props.user.userFromBackend.isVip === false ?
                                <div className="VkButton" onClick={
                                    isIOS ? this.handlerOpenRepostForIphone :
                                        this.handlerOpenNeedPremiumStatus
                                }>
                                    <img src={vk} alt=""/>
                                    <span>Профиль ВК</span>
                                </div>
                                :
                                <a className="VkButton" href={'https://vk.com/id' +
                                this.props.user.anotherUserProfile.vkId}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                >
                                    <img src={vk} alt=""/>
                                    <span>Профиль ВК</span>
                                </a>
                        }
                    </div>
                </div>
                <div className="ProfileHeartWrapperButton">
                            <LargeButton style={favoriteStatus ? pressButtonStyle : null}
                                         name={favoriteStatus ? 'Ваш' + (sex === 2 ? '' : 'а') +
                                             ' фаворит' + (sex === 2 ? '' : 'ка') :
                                             'В фаворит' + (sex === 2 ? 'ы' : 'ки')}
                                         action={this.handlerSetFavorite}
                                         disabled={disabled}/>
                    {
                        isIOS ? '' :
                            <Link to={{
                                pathname: "/send-present",
                                state: {
                                    id: id,
                                    first_name: first_name,
                                    isVip: this.props.user.userFromBackend.isVip
                                }
                            }} className="LinkStyle">
                                <LargeButton name="Подарить подарок"/>
                            </Link>
                    }
                </div>
                <div className="ProfilePhotoInTop">
                    <Title name="Фотографии в ТОПах"/>
                </div>
                <div className="WrapperProfile">
                </div>
                <UserProfilePhotos userProfilePhotos={userProfilePhotos}/>
                <NeedPremiumStatus
                    isOpen={needPremiumStatus}
                    handlerClose={this.handlerCloseNeedPremiumStatus}
                />
                <RepostForIphone
                    isOpen={repostForIphone}
                    handlerClose={this.handlerCloseRepostForIphone}
                />
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        photos: store.photos,
        user: store.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOtherUserPhotosFromBackendAction: (userId) => {
            dispatch(getOtherUserPhotosFromBackend(userId))
        },
        getUserByIdAction: (userId) => {
            dispatch(getUserById(userId))
        },
        getFavoriteStatusAction: (anotherUserProfileId) => {
            dispatch(getFavoriteStatus(anotherUserProfileId))
        },
        setFavoriteStatusAction: (anotherUserProfileId) => {
            dispatch(setFavoriteStatus(anotherUserProfileId))
        },
        saveCurrentPhotoAction: (photoId) => {
            dispatch(saveCurrentPhoto(photoId))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileId)