import React from 'react'
import './SetSex.css'
import Title from './../components/Title'
import SubTitle from './../components/SubTitle'
import { connect } from 'react-redux'
import {setAge, setUser} from "../actions/UserActions";
import FilterInput from "../components/FilterInput";
import DisabledLargeButton from "../components/DisabledLargeButton";

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            age: null,
        }
    }

    handleSaveAge = () => {
        const {sex} = this.props.user.ownUserProfile;
        // Нет пола
        if (sex !== 0) {
            this.props.setAgeAction(this.state.age);
            this.props.setUserAction();
            this.props.history.replace('/');
        }else {
            this.props.history.replace('/set-sex');
        }
    };

    handleChangeAge = (e) => {
        let age = e.target.value;
        this.setState({ age });
    };

    render() {
        const {age} = this.state;
        return (
            <div className="StartWrapper">
                <Title name={"У Вас указан возраст в ВК больше 100 лет или не указан вообще."}/>
                <SubTitle name={
                    "Прошу указать Ваш корректный возраст:"
                } />

                <div className="SectionSelect">
                    <div className="WrapperSelectRow">
                        <FilterInput className="FilterWidth" placeholder="Возраст"
                                     value={age !== null ? age : ''}
                                     handleChange={e => this.handleChangeAge(e)}
                        />
                    </div>
                    <DisabledLargeButton name="Применить"
                                         disabled={age === null || age[0] === '_' || age[1] === '_'}
                                         action={this.handleSaveAge}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAgeAction: (age) => {
            dispatch(setAge(age))
        },
        setUserAction: () => {
            dispatch(setUser())
        },
    }
};

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Start)