import React from 'react';
import Select, {createFilter} from 'react-select';

const colourStyles = {
    control: styles => ({ ...styles,
        backgroundColor: '#29222A',
        color: 'white',
        paddingLeft: '20px',
        borderRadius: '6px',
        border: '0'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: 'white',
            color: '#29222A',
        };
    },
    singleValue: styles => ({
        ...styles, color: 'white'
    })
};

class FilterSelect extends React.Component {

    render() {
        const {placeholder, className, options, noFoundMessage,
            needSelectCountry, isCountrySelect} = this.props;
        return (
            <Select
                value={this.props.selectedOption}
                onChange={this.props.handleChange}
                options={options}
                styles={colourStyles}
                placeholder={placeholder}
                isClearable={true}
                filterOption={createFilter({
                    matchFrom: 'label',
                    stringify: option => `${option.label}`,
                })}
                className={className}
                noOptionsMessage={() => <div style={{color: '#29222A'}}>{
                    isCountrySelect === 1 ? needSelectCountry : noFoundMessage}</div>}
                components={
                    {
                        IndicatorSeparator: () => null,
                    }
                }
            />
        );
    }
}

export default FilterSelect;