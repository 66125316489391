import React from 'react'
import './Start.css'
import Title from './../components/Title'
import SubTitle from './../components/SubTitle'
import MobileSvg from './../components/MobileSvg'
import Checkbox from './../components/Checkbox'
import { connect } from 'react-redux'
import {changeColorStatusBar} from "../actions/UserActions";
import DisabledLargeButton from "../components/DisabledLargeButton";

class Start extends React.Component {

    state = {
        checkbox1Agree: true,
        checkbox2Agree: true
    };
    componentDidMount() {
        this.props.changeColorStatusBarAction("#4C3B4F");
    }
    handleCheckbox1Change = (e) => {
        this.setState({ checkbox1Agree: e.currentTarget.checked })
    };

    handleCheckbox2Change = (e) => {
        this.setState({ checkbox2Agree: e.currentTarget.checked })
    };
    handlerGoToStartInfo = () => {
        this.props.history.replace('/save-gender-and-age');
    };
    render() {
        const { first_name, sex } = this.props.user.ownUserProfile;
        const { checkbox1Agree, checkbox2Agree} = this.state;
        return (
            <div className="StartWrapper">
                <Title
                    name={"Здравствуйте, " + first_name + "!" }
                    font={19}
                    weight={700}/>
                <div className="Line"></div>
                <SubTitle name={
                    "В нашем сервисе Вы можете познакомиться с топовыми " +
                    (sex === 1 ? "парнями" : (sex === 2 ? "девушками" : "девушками(парнями)"))
                     +" и узнать, насколько топов" +
                    (sex === 1 ? "ая" : (sex === 2 ? "ый" : "ый(ая)"))+" Вы"
                } />
                <MobileSvg/>
                <DisabledLargeButton name="Начать"
                             disabled={!(checkbox1Agree && checkbox2Agree) === true}
                             action={this.handlerGoToStartInfo}/>
                 <div className="Checkbox_Group">
                     <Checkbox name={
                         "Соглас"+
                         (sex === 1 ? "на c " : (sex === 2 ? "ен c " : "на(ен) c "))
                     }
                               checked={checkbox1Agree}
                               action={this.handleCheckbox1Change}
                               link="условиями"
                               afterName =" использования сервиса"/>
                     <Checkbox name={
                         "Соглас"+
                         (sex === 1 ? "на" : (sex === 2 ? "ен" : "на(ен)"))
                         +" участвовать в ТОПах"}
                               checked={checkbox2Agree}
                               action={this.handleCheckbox2Change}/>
                 </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeColorStatusBarAction: (color) => {
            dispatch(changeColorStatusBar(color))
        },
    }
};

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Start)