import React, { Component } from 'react';
import './App.css';
import AppRouter from './../../router/AppRouter';
import { connect } from 'react-redux'
import {getUserFromVk,checkSign} from "../../actions/UserActions";
import { Offline, Online } from "react-detect-offline";
import { Spinner } from '@vkontakte/vkui';
import socketIOClient  from 'socket.io-client';

class App extends Component {

    componentDidMount() {
        this.props.getUserFromVkAction();
        this.props.checkSignAction(window.location.href);

        const socket = socketIOClient(process.env.REACT_APP_NODE_PATH);
        socket.emit('new_visit', {
            sign: window.location.href,
        });
    }

    render() {
        //Если есть обращение к беку или
        //Нет начальных данных полученных из vk для пользователя
        if ((this.props.user.isFirstTime === null)||
            this.props.user.ownUserProfile.id === null ||
            (this.props.user.userFromBackend.id === null &&
                (this.props.user.isFirstTime === null ||
                this.props.user.isFirstTime === false))) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }else {
            if (this.props.sign === false || this.props.sign === null) {
                return (
                    <h1 style={{textAlign: 'center', paddingTop: '10%', color: 'white'}} >У Вас не совпадает аутентификационная подпись.<br/>
                        Пожалуйста, обратитесь к администратору этого приложения!</h1>
                );
            }

            const {bdate, first_name} = this.props.user.ownUserProfile;
            if (bdate !== null && bdate !== undefined) {
                //Если возраст полный(то есть 3 точки)
                if (bdate.split('.').length - 1 === 2) {
                    // Если возраст меньше 18
                    if (new Date().getFullYear() - Number(bdate.split('.')[2]) < 18) {
                        return (
                            <h1 style={{textAlign: 'center', paddingTop: '10%', color: 'white'}}>Здравствуйте, {first_name}!
                                <br/>
                                К сожалению, у Вас нет права на просмотр контента этого сервиса из-за ограничения возраста.
                                <br/>
                                Приносим свои извинения.</h1>
                        );
                    }
                }
            }

            const {user} = this.props;
            return (
                <div className="App">
                    <div className="App-header">
                        <Online><AppRouter user={user}/></Online>
                        <Offline><h3 style={{textAlign: 'center', paddingTop: '10%', color: 'white'}} >
                            Вы сейчас не в сети.<br/> Проверьте своё соединение</h3>
                        </Offline>
                    </div>
                </div>
            );
            }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserFromVkAction: () => {
            dispatch(getUserFromVk())
        },
        checkSignAction: (url) => {
            dispatch(checkSign(url))
        }
    }
};

const mapStateToProps = store => {
    return {
        user: store.user,
        photos: store.photos,
        sign: store.user.sign
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)