import React from 'react';
import Modal from 'react-modal';
import './StartPopup.css';
import Title from './../Title'
import SubTitle from './../SubTitle'
import LargeButton from "../LargeButton";
import PropTypes from 'prop-types';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        background            : 'transparent'
    },
    overlay: {
        zIndex                : '2000'
    }
};

Modal.setAppElement('#root');

function StartPopup(props)  {

        const {handlerClose, isOpen, gender} = props;
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={handlerClose}
                    style={customStyles}
                >

                    <div className="StartPopup">
                        <div className="StartPopup_ModalClose" onClick={handlerClose}>&times;</div>
                        <Title name={
                            "Отлично, теперь Вы можете раздать Ваши лайки топовым участни" +
                            (gender === 2 ? "цам." : "кам.")
                        }/>
                        <div className="SubTitleGroup">
                            <SubTitle name={
                                "Кстати, все " +
                                (gender === 2 ? "девушки" : "парни")
                            } />
                            <SubTitle name={
                                " узнают, что Вы отдали им Ваш драгоценный лайк."
                            } />
                        </div>

                        <div className="SubTitleGroup">
                            <SubTitle name={
                                "Если " +
                                (gender === 2 ? "девушка" : "парень")
                            } />
                            <SubTitle name=" лайкнет Вас в ответ," />
                            <SubTitle name="Вы узнаете это во вкладке" />
                            <SubTitle name='"Взаимные симпатии".' />
                        </div>
                        <LargeButton name="Начнем" action={handlerClose} />
                    </div>
                </Modal>
            </div>
        );
}

export default StartPopup;

StartPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handlerClose: PropTypes.func.isRequired,
    gender: PropTypes.number
}