import React from 'react';
import PropTypes from 'prop-types';
import './LargeButton.css';

function DisabledLargeButton(props){
    const { name, disabled, action, style} = props;
    return (
        <button className="DisabledLargeButton"
                onClick={action}
                disabled={disabled}
                style={style}>
            { name }
        </button>
    );
}

export default DisabledLargeButton;

DisabledLargeButton.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    action: PropTypes.func,
    style: PropTypes.object,
};