const initialState = {
    countries: [],
    cities: [],
    schools: [],
    universities: [],
    companies: [],
    positions: [],
    minAges: [],
    maxAges: [],
    relations: [],
    isLoading: null,
    country: null,
    city: null,
    school: null,
    university: null,
    company: null,
    position: null,
    relation: null,
    isMale: null,
    minAge: null,
    maxAge: null,
};

export function filterReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_All_COUNTRIES_REQUEST':
            return {...state, isLoading: true};
        case 'GET_All_COUNTRIES_SUCCESS':
            return {...state, countries: action.payload, isLoading: false};

        case 'GET_All_CITIES_REQUEST':
            return {...state, isLoading: true};
        case 'GET_All_CITIES_SUCCESS':
            return {...state, cities: action.payload, isLoading: false};

        case 'GET_All_SCHOOLS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_All_SCHOOLS_SUCCESS':
            return {...state, schools: action.payload, isLoading: false};

        case 'GET_All_UNIVERSITIES_REQUEST':
            return {...state, isLoading: true};
        case 'GET_All_UNIVERSITIES_SUCCESS':
            return {...state, universities: action.payload, isLoading: false};

        case 'GET_All_WORKS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_All_WORKS_SUCCESS':
            return {...state,
                companies: action.payload.companies,
                positions: action.payload.positions,
                isLoading: false};

        case 'GET_All_AGE_AND_RELATION_REQUEST':
            return {...state, isLoading: true};
        case 'GET_All_AGE_AND_RELATION_SUCCESS':
            return {...state,
                minAges: action.payload.minAges,
                maxAges: action.payload.maxAges,
                relations: action.payload.relations,
                Loading: false};

        case 'SAVE_FILTER_IN_BACKEND_REQUEST':
            return {...state, isLoading: true};
        case 'SAVE_FILTER_IN_BACKEND_SUCCESS':
            return {...state,
                country: action.payload.country,
                city: action.payload.city,
                school: action.payload.school,
                university: action.payload.university,
                company: action.payload.company,
                position: action.payload.position,
                relation: action.payload.relation,
                isMale: action.payload.isMale,
                minAge: action.payload.minAge,
                maxAge: action.payload.maxAge,
                isLoading: false };

        case 'GET_USER_FILTER_REQUEST':
            return {...state, isLoading: true};
        case 'GET_USER_FILTER_SUCCESS':
            let filters = {};
            if (action.payload) {
                action.payload.map( (item) => {
                    return filters[Object.keys(item)[0]] = item;
                });
                return {...state,
                    country: filters.country ? filters.country.country : null,
                    city: filters.city ? filters.city.city : null,
                    school: filters.school ? filters.school.school : null,
                    university: filters.university ? filters.university.university : null,
                    company:filters.company ? filters.company.company : null,
                    position: filters.position ? filters.position.position : null,
                    relation: filters.relation ? filters.relation.relation : null,
                    isMale: filters.isMale ? filters.isMale.isMale : null,
                    minAge: filters.minAge ? filters.minAge.minAge : null,
                    maxAge: filters.maxAge ? filters.maxAge.maxAge : null,
                    isLoading: false };
            }else {
                return {...state, isLoading: false}
            }
        case 'RESET_FILTER_REQUEST':
            return {...state, isLoading: true};
        case 'RESET_FILTER_SUCCESS':
            return {...state, isLoading: false,
                country: null,
                city: null,
                school: null,
                university: null,
                company: null,
                position: null,
                relation: null,
                isMale: null,
                minAge: null,
                maxAge: null,};
        default:
            return state
    }
}