// import VKConnect from '@vkontakte/vkui-connect-mock';
import VKConnectPromise from '@vkontakte/vkui-connect-promise';

import API from '../plugins/axios'
import {getUserFromBackend} from "./UserActions";

const GET_USER_PHOTOS_FROM_BACKEND_REQUEST = 'GET_USER_PHOTOS_FROM_BACKEND_REQUEST';
const GET_USER_PHOTOS_FROM_BACKEND_SUCCESS = 'GET_USER_PHOTOS_FROM_BACKEND_SUCCESS';

const GET_OTHER_USER_PHOTOS_FROM_BACKEND_REQUEST = 'GET_OTHER_USER_PHOTOS_FROM_BACKEND_REQUEST';
const GET_OTHER_USER_PHOTOS_FROM_BACKEND_SUCCESS = 'GET_OTHER_USER_PHOTOS_FROM_BACKEND_SUCCESS';

const GET_TOP_PHOTOS_REQUEST = 'GET_TOP_PHOTOS_REQUEST';
const GET_TOP_PHOTOS_SUCCESS = 'GET_TOP_PHOTOS_SUCCESS';
const GET_TOP_PHOTOS_ERROR = 'GET_TOP_PHOTOS_ERROR';

const UPDATE_TOP_PHOTOS_WHEN_LIKE = 'UPDATE_TOP_PHOTOS_WHEN_LIKE';

const UPDATE_TOP_PHOTOS_REQUEST = 'UPDATE_TOP_PHOTOS_REQUEST';
const UPDATE_TOP_PHOTOS_SUCCESS = 'UPDATE_TOP_PHOTOS_SUCCESS';
const UPDATE_TOP_PHOTOS_ERROR = 'UPDATE_TOP_PHOTOS_ERROR';

const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_ERROR = 'UPLOAD_PHOTO_ERROR';

const CLOSE_ADD_PHOTO_MODAL = 'CLOSE_ADD_PHOTO_MODAL';
const CLOSE_FAIL_UPLOAD_PHOTO_MODAL = 'CLOSE_FAIL_UPLOAD_PHOTO_MODAL';

const GET_RATING_PHOTOS_REQUEST = 'GET_RATING_PHOTOS_REQUEST';
const GET_RATING_PHOTOS_SUCCESS = 'GET_RATING_PHOTOS_SUCCESS';
const GET_RATING_PHOTOS_ERROR = 'GET_RATING_PHOTOS_ERROR';

const SET_LIKE_REQUEST = 'SET_LIKE_REQUEST';
const SET_LIKE_SUCCESS = 'SET_LIKE_SUCCESS';

const GET_MY_FAVORITES_REQUEST = 'GET_MY_FAVORITES_REQUEST';
const GET_MY_FAVORITES_SUCCESS = 'GET_MY_FAVORITES_SUCCESS';
const GET_MY_FAVORITES_ERROR = 'GET_MY_FAVORITES_ERROR';

const UPDATE_MY_FAVORITES_REQUEST = 'UPDATE_MY_FAVORITES_REQUEST';
const UPDATE_MY_FAVORITES_SUCCESS = 'UPDATE_MY_FAVORITES_SUCCESS';
const UPDATE_MY_FAVORITES_ERROR = 'UPDATE_MY_FAVORITES_ERROR';

const GET_I_LIKE_REQUEST = 'GET_I_LIKE_REQUEST';
const GET_I_LIKE_SUCCESS = 'GET_I_LIKE_SUCCESS';
const GET_I_LIKE_ERROR = 'GET_I_LIKE_ERROR';

const UPDATE_I_LIKE_REQUEST = 'UPDATE_I_LIKE_REQUEST';
const UPDATE_I_LIKE_SUCCESS = 'UPDATE_I_LIKE_SUCCESS';
const UPDATE_I_LIKE_ERROR = 'UPDATE_I_LIKE_ERROR';

const GET_YOU_LIKE_REQUEST = 'GET_YOU_LIKE_REQUEST';
const GET_YOU_LIKE_SUCCESS = 'GET_YOU_LIKE_SUCCESS';
const GET_YOU_LIKE_ERROR = 'GET_YOU_LIKE_ERROR';

const UPDATE_YOU_LIKE_REQUEST = 'UPDATE_YOU_LIKE_REQUEST';
const UPDATE_YOU_LIKE_SUCCESS = 'UPDATE_YOU_LIKE_SUCCESS';
const UPDATE_YOU_LIKE_ERROR = 'UPDATE_YOU_LIKE_ERROR';

const GET_ALLS_LIKES_REQUEST = 'GET_ALLS_LIKES_REQUEST';
const GET_ALLS_LIKES_SUCCESS = 'GET_ALLS_LIKES_SUCCESS';
const GET_ALLS_LIKES_ERROR = 'GET_ALLS_LIKES_ERROR';

const UPDATE_ALLS_LIKES_REQUEST = 'UPDATE_ALLS_LIKES_REQUEST';
const UPDATE_ALLS_LIKES_SUCCESS = 'UPDATE_ALLS_LIKES_SUCCESS';
const UPDATE_ALLS_LIKES_ERROR = 'UPDATE_ALLS_LIKES_ERROR';

const IS_ALLS_SLUG = 'IS_ALLS_SLUG';
const UPDATE_AVATAR_PHOTO_REQUEST = 'UPDATE_AVATAR_PHOTO_REQUEST';
const UPDATE_AVATAR_PHOTO_SUCCESS = 'UPDATE_AVATAR_PHOTO_SUCCESS';
const UPDATE_AVATAR_PHOTO_ERROR = 'UPDATE_AVATAR_PHOTO_ERROR';

const SORT_RATING_NEWS_PHOTOS_REQUEST = 'SORT_RATING_NEWS_PHOTOS_REQUEST';
const SORT_RATING_NEWS_PHOTOS_SUCCESS = 'SORT_RATING_NEWS_PHOTOS_SUCCESS';

const HISTORY_SEND_SUCCESS = 'HISTORY_SEND_SUCCESS';
const HISTORY_SEND_ERROR = 'HISTORY_SEND_ERROR';
const HISTORY_SEND_REQUEST = 'HISTORY_SEND_REQUEST';
const HISTORY_SEND_CANCEL = 'HISTORY_SEND_CANCEL';
const HISTORY_MODAL_CLOSE = 'HISTORY_MODAL_CLOSE';

const SAVE_CURRENT_PHOTO = 'SAVE_CURRENT_PHOTO';
const CLOSE_SHOW_POPUP_FREE_PREMIUM = 'CLOSE_SHOW_POPUP_FREE_PREMIUM';

const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST';
const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
const DELETE_PHOTO_ERROR = 'DELETE_PHOTO_ERROR';

export function uploadPhotos(files) {
    return async (dispatch, getState) => {
        dispatch({
            type: UPLOAD_PHOTO_REQUEST
        });
        const bodyFormData = new FormData();
        let sign = getState().user.sign;
        bodyFormData.append('sign', sign);
        let i=1;
        files.forEach( file => {
            bodyFormData.append('file' + i, file);
            i++;
        });
        API({
            url:'/photo/upload-photos',
            method: 'post',
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: bodyFormData
        }).then(function (response) {
            dispatch({
                type: UPLOAD_PHOTO_SUCCESS,
                payload: response.data
            });
        }).catch(function (error) {
            dispatch({
                type: UPLOAD_PHOTO_ERROR,
            });
            });
    }
}

export function closeAddPhotoModal() {
    return  (dispatch) => {
        dispatch({
            type: CLOSE_ADD_PHOTO_MODAL,
        });
    }
}

export function closeFailUploadPhotoModal() {
    return  (dispatch) => {
        dispatch({
            type: CLOSE_FAIL_UPLOAD_PHOTO_MODAL,
        });
    }
}

/**
 * Получение фотографии пользователя участвующие в оценке
 * @returns {Function}
 */
export function getUserPhotosFromBackend(url) {
     return async (dispatch,getState) => {
         let sign = getState().user.sign;
         await dispatch({
             type: GET_USER_PHOTOS_FROM_BACKEND_REQUEST,
         });
        await API({
            url:'/photo/get-profile-photos',
            method: 'post',
            data: {
                'sign': sign,
                'url': url
            }
        }).then(function (response) {
            dispatch({
                type: GET_USER_PHOTOS_FROM_BACKEND_SUCCESS,
                payload: response.data,
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Получение фотографии пользователя участвующие в оценке
 * @returns {Function}
 */
export function getOtherUserPhotosFromBackend(userId) {
    return async (dispatch,getState) => {
        let sign = getState().user.sign;
        await dispatch({
            type: GET_OTHER_USER_PHOTOS_FROM_BACKEND_REQUEST,
        });
        await API({
            url:'/photo/get-other-profile-photos',
            method: 'post',
            data: {
                'sign': sign,
                'url': '/profile-rating/',
                'otherId': userId
            }
        }).then(function (response) {
            dispatch({
                type: GET_OTHER_USER_PHOTOS_FROM_BACKEND_SUCCESS,
                payload: response.data,
            });
        }).catch(function (error) {
                console.log(error)
            });
    }
}

export function getTopPhotos(slug,gender, offset) {
    return (dispatch,getState,) => {
        let sign = getState().user.sign;
        dispatch({
            type: GET_TOP_PHOTOS_REQUEST,
        });
        return API({
            url:'/photo/top-photos',
            method: 'post',
            data: {
                'sign': sign,
                'slug': slug,
                'gender': gender,
                'offset': offset
            },
        }).then((response) => {
            dispatch({
                type: GET_TOP_PHOTOS_SUCCESS,
                payload: response.data,
            });

            return Promise.resolve(response);
        }).catch((error) => {
            dispatch({
                type: GET_TOP_PHOTOS_ERROR,
            });

            return Promise.error(error);
        });
    }
}

export function updateTopPhotos(slug, gender, offset) {
    return (dispatch,getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: UPDATE_TOP_PHOTOS_REQUEST,
        });
        return API({
            url:'/photo/top-photos',
            method: 'post',
            data: {
                'sign': sign,
                'slug': slug,
                'gender': gender,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: UPDATE_TOP_PHOTOS_SUCCESS,
                payload: response.data,
            });

            return response;
        }).catch(function (error) {
            dispatch({
                type: UPDATE_TOP_PHOTOS_ERROR,
            });

            return error;
        });
    }
}

export function getRatingPhotos(slug, photoId) {
    return async (dispatch, getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: GET_RATING_PHOTOS_REQUEST
        });
        await API({
            url:'/photo/get-rating',
            method: 'post',
            data: {
                'sign': sign,
                'slug': slug,
                'photo_which_view': photoId
            },
        }).then(function (response) {
            if (slug === 'alls') {
                dispatch({
                    type: IS_ALLS_SLUG,
                    payload: true
                });
            }else {
                dispatch({
                    type: IS_ALLS_SLUG,
                    payload: false
                });
            }
            dispatch({
                type: GET_RATING_PHOTOS_SUCCESS,
                payload: response.data,
            });
        })
            .catch(function () {
                dispatch({
                    type: GET_RATING_PHOTOS_ERROR
                });
            });
    }
}

export function sortRatingNewsPhotos(photoId) {
    return async (dispatch,getState) => {
        await dispatch({
            type:  SORT_RATING_NEWS_PHOTOS_REQUEST
        });

        let sign = getState().user.sign;
        await API({
            url:'/photo/photo-is-view',
            method: 'post',
            data: {
                'sign': sign,
                'photo_which_view' : photoId,
            },
        }).then(function (response) {
            dispatch({
                type:  SORT_RATING_NEWS_PHOTOS_SUCCESS,
                payload: response.data
            });
        })
        .catch(function (error) {
            console.log(error)
        });
    }
}

export function showNativeImg(vkPhotos) {
    return async () => {
        /**
         * Массив для хранения урлов для нативного просмотра
         * @type {Array}
         */
        const photosUrl = [];

        vkPhotos.map(photo => {
            return photosUrl.push(photo.url)
        });

        VKConnectPromise.send("VKWebAppShowImages", {
            images: photosUrl
        });
    }
}

export function showNativeOneImg(photoUrl) {
    return () => {
        VKConnectPromise.send("VKWebAppShowImages", {
            images: [
                photoUrl
            ]
        });
    }
}

/**
 *
 * @param photoId
 * @returns {Function}
 */
export function setLike(photoId, slug, gender, offset) {
    return async (dispatch, getState) => {
        let sign = getState().user.sign;

        return await API({
            url:'/like/set-like-photo',
            method: 'post',
            data: {

                'sign': sign,
                'photo_which_like' : photoId,
            },
        }).then(function (response) {
            if (!response.data.error) {
                dispatch({
                    type:  SET_LIKE_SUCCESS,
                    payload: response.data
                });
            }

             return API({
                url:'/photo/update-top-photos-when-like',
                method: 'post',
                data: {
                    'sign': sign,
                    'slug': slug,
                    'gender': gender,
                    'offset': offset,
                    'photoId': photoId
                },
            }).then((response) => {
                dispatch({
                    type: UPDATE_TOP_PHOTOS_WHEN_LIKE,
                    payload: response.data,
                });

                 return Promise.resolve(response);
            }).catch((error) => {

                 return Promise.error(error);
            });

        }).catch(function (error) {
            console.log(error)

            return error;
            });
    }
}

/**
 * Получение юзеров, которые я лайкнул
 * @param offset
 * @returns {Function}
 */
export function getILike(offset) {
    return (dispatch,  getState) => {
        dispatch({
            type: GET_I_LIKE_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/photo/get-i-like',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: GET_I_LIKE_SUCCESS,
                payload: response.data,
            });

            return Promise.resolve(response);
        }).catch(function (error) {
            dispatch({
                type: GET_I_LIKE_ERROR
            });

            return Promise.error(error);
        });
    }

}

/**
 * Обновление при скролле при получении юзеров которые я лайкнул
 * @param offset
 * @returns {Function}
 */
export function updateILike(offset) {
    return async (dispatch,  getState) => {
        dispatch({
            type: UPDATE_I_LIKE_REQUEST
        });
        let sign = getState().user.sign;
        await API({
            url:'/photo/get-i-like',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: UPDATE_I_LIKE_SUCCESS,
                payload: response.data,
            });

        }).catch(function (error) {
            dispatch({
                type: UPDATE_I_LIKE_ERROR
            });
        });
    }

}

/**
 * Получение пользователей которые тебя лайкнули
 * @param offset
 * @returns {Function}
 */
export function getYouLike(offset) {
    return (dispatch,  getState) => {
        dispatch({
            type: GET_YOU_LIKE_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/photo/get-you-like',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: GET_YOU_LIKE_SUCCESS,
                payload: response.data,
            });

            return Promise.resolve(response);
        }).catch(function (error) {
            dispatch({
                type: GET_YOU_LIKE_ERROR
            });

            return Promise.error(error);
        });
    }

}

/**
 * Обновление при скролле при получении пользователей которые тебя лайкнули
 * @param offset
 * @returns {Function}
 */
export function updateYouLike(offset) {
    return async (dispatch,  getState) => {
        dispatch({
            type: UPDATE_YOU_LIKE_REQUEST
        });
        let sign = getState().user.sign;
        await API({
            url:'/photo/get-you-like',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: UPDATE_YOU_LIKE_SUCCESS,
                payload: response.data,
            });

        }).catch(function (error) {
            dispatch({
                type: UPDATE_YOU_LIKE_ERROR
            });
        });
    }

}

/**
 * Получение моих фавориток
 * @param offset
 * @returns {Function}
 */
export function getMyFavorites(offset) {
    return (dispatch,  getState) => {
        dispatch({
           type: GET_MY_FAVORITES_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/photo/get-my-favorites',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: GET_MY_FAVORITES_SUCCESS,
                payload: response.data,
            });

            return Promise.resolve(response);
        }).catch(function (error) {
            dispatch({
                type: GET_MY_FAVORITES_ERROR
            });

            return Promise.error(error);
        });
    }

}

/**
 * Обновление при скролле при получении моих фавориток
 * @param offset
 * @returns {Function}
 */
export function updateMyFavorites(offset) {
    return async (dispatch,  getState) => {
        dispatch({
            type: UPDATE_MY_FAVORITES_REQUEST
        });
        let sign = getState().user.sign;
        await API({
            url:'/photo/get-my-favorites',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type: UPDATE_MY_FAVORITES_SUCCESS,
                payload: response.data,
            });

        }).catch(function (error) {
            dispatch({
                type: UPDATE_MY_FAVORITES_ERROR
            });
        });
    }

}

/**
 * Получение взаимных лайков
 * @returns {Function}
 */
export function getAllsLikes(offset) {
    return async (dispatch,  getState) => {
        dispatch({
            type:  GET_ALLS_LIKES_REQUEST
        });
        let sign = getState().user.sign;
        await API({
            url:'/photo/get-alls-likes',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type:  GET_ALLS_LIKES_SUCCESS,
                payload: response.data,
            });

            return Promise.resolve(response);
        }).catch(function (error) {
            dispatch({
                type: GET_ALLS_LIKES_ERROR
            });

            return Promise.error(error);
        });
    }

}

/**
 * Обновление взаимных лайков при скролле
 * @returns {Function}
 */
export function updateAllsLikes(offset) {
    return async (dispatch,  getState) => {
        dispatch({
            type:  UPDATE_ALLS_LIKES_REQUEST
        });
        let sign = getState().user.sign;
        await API({
            url:'/photo/get-alls-likes',
            method: 'post',
            data: {
                'sign': sign,
                'offset': offset
            },
        }).then(function (response) {
            dispatch({
                type:  UPDATE_ALLS_LIKES_SUCCESS,
                payload: response.data,
            });

        }).catch(function (error) {
            dispatch({
                type: UPDATE_ALLS_LIKES_ERROR
            });
        });
    }

}

/**
 * Сохранение аватарки пользователя в фотках для оценки
 * @returns {Function}
 */
export function uploadAvatarPhoto() {
    return (dispatch, getState) => {
        let sign = getState().user.sign;
            dispatch({
                type: UPDATE_AVATAR_PHOTO_REQUEST
            });
            API({
                url:'/user/save-avatar',
                method: 'post',
                data: {
                    'sign': sign
                },
            }).then(function () {
                dispatch({
                    type: UPDATE_AVATAR_PHOTO_SUCCESS
                });
                dispatch(getUserPhotosFromBackend('/'));
            }).catch(function () {
                dispatch({
                    type: UPDATE_AVATAR_PHOTO_ERROR
                });
            });
    }
}

export function onHistory(urlPhoto, photoId) {
    return (dispatch, getState) => {
        dispatch({
            type: HISTORY_SEND_REQUEST,
        });
        VKConnectPromise.send("VKWebAppGetAuthToken",
            {"app_id": Number(process.env.REACT_APP_VK_APP_ID),"scope": "stories"})
            .then( response => {
                if(response.data.scope !== "") {
                    let token = response.data.access_token;
                    VKConnectPromise.send("VKWebAppCallAPIMethod",
                        {"method": "stories.getPhotoUploadServer",
                            "params": {"v":"5.101",
                                "access_token":token,
                                "add_to_news":1,
                                "link_url":"https://vk.com/app" + process.env.REACT_APP_VK_APP_ID
                            }})
                        .then( response => {
                            let upload_url  = response.data.response.upload_url ;
                            let sign = getState().user.sign;

                            API({
                                url:'/photo/save-history',
                                method: 'post',
                                data: {
                                    'sign': sign,
                                    'upload_url': upload_url,
                                    'url_photo': urlPhoto,
                                    'photo_id': photoId,
                                }
                            }).then((response) => {
                                dispatch({
                                    type: HISTORY_SEND_SUCCESS,
                                    payload: response.data
                                });
                            }).catch(() => {
                                dispatch({
                                    type: HISTORY_SEND_ERROR,
                                });
                            });
                        })
                        .catch( () => {
                            dispatch({
                                type: HISTORY_SEND_ERROR,
                            });
                        });
                }else {
                    dispatch({
                        type: HISTORY_SEND_ERROR,
                    });
                }

            })
            .catch( () => {
                dispatch({
                    type: HISTORY_SEND_CANCEL,
                });
            });
    }
}

/**
 * Закрытие модалки для отправки фото в историю
 * @returns {Function}
 */
export function closeHistorySendModal() {
    return (dispatch) => {
        dispatch({
            type: HISTORY_MODAL_CLOSE,
        });
    }
}

/**
 * Сохранение текущей фото в слайдере
 * @param photoId
 * @returns {Function}
 */
export function saveCurrentPhoto(photoId) {
    return (dispatch) => {
        dispatch({
           type: SAVE_CURRENT_PHOTO,
            payload: photoId
        });
    }
}

export function deletePhoto(id) {
    return (dispatch, getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: DELETE_PHOTO_REQUEST
        });
        API({
            url:'/photo/delete-photo',
            method: 'post',
            data: {
                'sign': sign,
                'photoId': id
            },
        }).then(function (response) {
            dispatch({
                type: DELETE_PHOTO_SUCCESS,
                payload: response.data
            });
            dispatch(getUserPhotosFromBackend('/'));
        }).catch(function () {
            dispatch({
                type: DELETE_PHOTO_ERROR
            });
        });
    }
}

/**
 * Закрытие модалки для с предложением бесплатного премиума
 * @returns {Function}
 */
export function closeShowPopupFreePremium() {
    return (dispatch) => {
        dispatch({
            type: CLOSE_SHOW_POPUP_FREE_PREMIUM,
        });
    }
}