import React,{Component} from 'react';
import MenuTops from './../components/MenuTops';
import './Tops.css';
import {getTopPhotos, updateTopPhotos, setLike} from "../actions/PhotosActions";
import { connect } from 'react-redux'
import { Spinner } from '@vkontakte/vkui';
import TopPhotos from './../components/TopPhotos';


class Tops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topPhotos: null,
            offset: 5
        };
    }

    componentDidMount() {
        const {gender,slug} = this.props.match.params;
        this.props.getTopPhotosAction(slug,gender,0);
        window.scrollTo(0,0);

        setTimeout(() => {
            this.setState({
                topPhotos: this.props.photos.topPhotos
            });
        }, 1000);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {gender,slug} = this.props.match.params;
        if (nextProps.match.params.gender !== gender) {
            this.props.getTopPhotosAction(slug,nextProps.match.params.gender,0)
            this.setState(({
                offset: 5,
            }));
        }
        if (nextProps.match.params.slug !== slug) {
            this.props.getTopPhotosAction(nextProps.match.params.slug,gender,0)
            this.setState(({
                offset: 5,
            }));
        }
    }

        /**
     * Хендлер для добавления лайков в БД
     * @param int photoId
     */
    handleAddLike = (photoId) =>{
        const {offset} = this.state;
        const {gender,slug} = this.props.match.params;
        this.props.setLikeAction(photoId, slug, gender, offset)
            .then( (response) => {
                this.setState({
                    topPhotos: response.data.photos
                });

                this.setState({offset: response.data.offset});
            })
            .catch( (error) => {
                console.log(error)
            });
    };

    handlerscrollToRefresh = () => {
        const {gender,slug} = this.props.match.params;
        const {offset} = this.state;
        this.props.updateTopPhotosAction(slug, gender, offset)
            .then( (response) => {
                this.setState({
                    topPhotos: this.state.topPhotos.concat(response.data)
                });
            })
            .then( () => {
                this.setState(prevState => ({
                    offset: prevState.offset + 5,
                }));
            })
            .catch( (error) => {
                console.log(error)
            });
    };

    handlerGoTo = (url) => {
        this.props.history.push(url);
    };

     handlerGoToUser = (id) => {
         if(Number(id) === this.props.user.ownUserProfile.id) {
             this.props.history.push('/');
         }else {
             this.props.history.push('/' + id);
         }
    };

    render() {
        if ( this.props.photos.isLoading === true ||
            this.props.user.isLoading === true ||
            this.state.topPhotos === null) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }else {
            const {topPhotos} = this.props.photos;
            const {user} = this.props;
            const {gender,slug} = this.props.match.params;
            return (
                <div className="Top__PaddingToTop PaddingToBottom">
                    <MenuTops slug={slug} gender={gender} history={this.props.history}/>
                    <div className="WrapperTop Padding">
                    </div>
                    {
                        topPhotos.length !== 0  ? <TopPhotos
                            topPhotos={topPhotos}
                            user={user}
                            handlerGoToUser={this.handlerGoToUser}
                            scrollToRefresh={() => this.handlerscrollToRefresh}
                            handleAddLike = { photoId => this.handleAddLike(photoId)}
                        /> : <h3>Фотографии нет</h3>
                    }
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        photos: store.photos,
        user: store.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopPhotosAction: (slug, gender, offset) => {
            dispatch(getTopPhotos(slug, gender, offset))
        },
        updateTopPhotosAction: (slug, gender, offset) => {
            return dispatch(updateTopPhotos(slug, gender, offset))
        },
        setLikeAction: (photoId, slug, gender, offset) => {
            return dispatch(setLike(photoId, slug, gender, offset))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tops)