import React from 'react';
import PropTypes from 'prop-types';
import './LargeButton.css';

function LargeButton(props){
    const { name, disabled, action, style} = props;
        return (
            <button className="LargeButton"
                    onClick={action}
                    disabled={disabled}
                    style={style}>
                { name }
            </button>
        );
}

export default LargeButton;

LargeButton.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    action: PropTypes.func,
    style: PropTypes.object,
};