import React from 'react';
import './SubTitle.css';
import PropTypes from 'prop-types';

function SubTitle(props) {
    return (
        <div className="SubTitle">
            { props.name }
        </div>
    );
}

export default SubTitle;

SubTitle.propTypes = {
    name: PropTypes.string,
};