import React, {Component} from 'react';
import {getMyFavorites, updateMyFavorites} from "../actions/PhotosActions";
import { connect } from 'react-redux'
import { Spinner } from '@vkontakte/vkui';
import ImageItem from "../components/ImageItem";

class MyFavorites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myFavorites: null,
            offset: 5
        };
    }

    componentDidMount() {
        this.props.getMyFavoritesAction(0);
        window.scrollTo(0,0);

        setTimeout(() => {
            this.setState({
                myFavorites: this.props.photos.myFavorites
            });
        }, 1000);
    }

    handlerscrollToRefresh = () => {
        const {offset} = this.state;
        this.props.updateMyFavoritesAction(offset)
            .then( (response) => {
                if (response) {
                    this.setState({
                        myFavorites: this.state.myFavorites.concat(response.data)
                    });
                }
            })
            .then( () => {
                this.setState(prevState => ({
                    offset: prevState.offset + 5,
                }));
            })
            .catch( (error) => {
                console.log(error)
            });

    };

    render() {
        if (this.props.photos.isLoading === true ||
            this.props.user.isLoading === true ||
            this.state.myFavorites === null
        ) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }else {
            const {myFavorites} = this.props.photos;
            const {gender} = this.props.user.userFromBackend;
            return (
                <div className={myFavorites.length === 1 || myFavorites.length === 0 ? "Fixed" : "WrapperPremium"}
                >
                    <div className="PaddingToTop PaddingToBottom">
                            {
                                myFavorites.length !== 0 ? <ImageItem
                                        items={myFavorites}
                                        scrollToRefresh={() => this.handlerscrollToRefresh} /> :
                                    ( gender === 2 ? <h3>У Вас пока нет фавориток</h3>: <h3>У Вас пока нет фаворитов</h3>)
                            }
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMyFavoritesAction: (offset) => {
            dispatch(getMyFavorites(offset))
        },
        updateMyFavoritesAction: (offset) => {
            return dispatch(updateMyFavorites(offset))
        },
    }
};

const mapStateToProps = store => {
    return {
        photos: store.photos,
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyFavorites)