import React from 'react';
import './../cabinet/Cabinet.css';
import CabinetFooter from './../../components/CabinetFooter';
import Header from "../../components/Header";

const SameFooter = ({ children, ...props }) => {
    return (
        <div className="Cabinet SameFooter_Cabinet">
            <div className="Header">
                <Header {...props} />
            </div>
            <div className="Content">
                {children}
            </div>
            <div className="Footer">
                <CabinetFooter {...props}/>
            </div>
        </div>
    )
}

export default SameFooter;