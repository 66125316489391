import React, {Component} from 'react';
import './Settings.css';
import LargeButton from './../components/LargeButton';
import DisabledLargeButton from './../components/DisabledLargeButton';
import Switch from './../components/Switch';
import { connect } from 'react-redux';
import {saveSetting, deleteAccount} from "../actions/UserActions";
import DeleteAccount from "../components/modals/DeleteAccount";

class Settings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isAvatarToTop: this.props.user.userFromBackend.isAvatarToTop,
            isShowPhotos: this.props.user.userFromBackend.isShowPhotos,
            isShowDeleteAccountModal: false
        }
    }

    handlerIsAvatarToTop = () => {
        this.setState(prevState => ({
            isAvatarToTop: !prevState.isAvatarToTop,
        }));
    };

    handlerIsShowPhotos = () => {
        this.setState(prevState => ({
            isShowPhotos: !prevState.isShowPhotos,
        }));
    };

    handlerSaveSetting = () => {
            this.props.saveSettingAction(
                this.state.isAvatarToTop,
                this.state.isShowPhotos
            );
    };

    /**
     * Удалить аккаунт
     */
    handlerDeleteAccount = () => {
        this.props.deleteAccountAction();
        this.props.history.replace('/');
    };

    /**
     * Хендлер для показа модалки удаления аккаунта
     */
    handlerDeleteAccountOpen = () => {
        this.setState({isShowDeleteAccountModal: true})
    };

    /**
     * Хендлер для закрытия модалки удаления аккаунта
     */
    handlerDeleteAccountClose = () => {
        this.setState({isShowDeleteAccountModal: false})
    };

    render() {
        const {isAvatarToTop,isShowPhotos} = this.state;
        return (
            <div className="PaddingToTop">
                <div className="Settings">
                    <div className="SettingsSwitchWrapper">

                        <div className="SettingsSwitch">
                            <span>Добавлять в ТОП все мои<br/> новые аватарки</span>
                            <Switch
                                isChecked={isAvatarToTop}
                                action={this.handlerIsAvatarToTop}
                            />
                        </div>
                        <div className="SettingsSwitch">
                            <span>Показывать мои фото в ТОПах<br/> и оценках</span>
                            <Switch
                                isChecked={isShowPhotos}
                                action={this.handlerIsShowPhotos}
                            />
                        </div>
                    </div>
                    <div className="WrapperButton">
                        <DisabledLargeButton
                            name="Сохранить"
                            action={this.handlerSaveSetting}
                            disabled={this.props.user.userFromBackend.isAvatarToTop === isAvatarToTop &&
                            this.props.user.userFromBackend.isShowPhotos === isShowPhotos}
                        />
                        <LargeButton
                            name="Удалить аккаунт"
                            action={this.handlerDeleteAccountOpen}
                            style={
                                {
                                    "background": "#FF3E3E",
                                    "border": "1px solid #FF3E3E",
                                }}/>
                    </div>
                    <div className="Settings__Dogovor">
                            <a className="UnderLink"
                               href={'/dogovor.html'}
                               target="_blank"
                               rel="noopener noreferrer" >
                                Условия использования
                            </a>
                    </div>
                </div>
                <DeleteAccount
                    isOpen={this.state.isShowDeleteAccountModal}
                    handlerClose={this.handlerDeleteAccountClose}
                    handlerChange={this.handlerDeleteAccount}
                    title="Точно удалить?"
                    color="#FFFFFF"
                />
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveSettingAction: (isAvatarToTop, isShowPhotos) => {
            dispatch(saveSetting(isAvatarToTop, isShowPhotos))
        },
        deleteAccountAction: () => {
            dispatch(deleteAccount())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)