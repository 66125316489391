import React,{Component} from 'react';
import {getAllsLikes, updateAllsLikes} from "../actions/PhotosActions";
import { connect } from 'react-redux'
import { Spinner } from '@vkontakte/vkui';
import ImageItem from "../components/ImageItem";

class AllsLikes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allsLikes: null,
            offset: 5
        };
    }

    componentDidMount() {
        this.props.getAllsLikesAction(0);
        window.scrollTo(0,0);

        setTimeout(() => {
            this.setState({
                allsLikes: this.props.photos.allsLikes
            });
        }, 1000);
    }

    handlerscrollToRefresh = () => {
        // const {offset} = this.state;
        // this.props.updateMyFavoritesAction(offset)
        //     .then( (response) => {
        //         if (response) {
        //             this.setState({
        //                 allsLikes: this.state.allsLikes.concat(response.data)
        //             });
        //         }
        //     })
        //     .then( () => {
        //         this.setState(prevState => ({
        //             offset: prevState.offset + 5,
        //         }));
        //     })
        //     .catch( (error) => {
        //         console.log(error)
        //     });

    };

    render() {
        if (this.props.photos.isLoading === true ||
            this.state.allsLikes === null) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            );
        }else {
            const {allsLikes} = this.props.photos;
            return (
                <div className={allsLikes.length === 1 || allsLikes.length === 0 ? "Fixed" : "WrapperPremium"}
                >
                    <div className="PaddingToTop PaddingToBottom">
                        {
                            allsLikes.length !== 0 ? <ImageItem
                                    items={allsLikes}
                                    scrollToRefresh={() => this.handlerscrollToRefresh} /> :
                                <h3>У Вас пока нет взаимных симпатий</h3>
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllsLikesAction: (offset) => {
            dispatch(getAllsLikes(offset))
        },
        updateAllsLikesAction: (offset) => {
            return dispatch(updateAllsLikes(offset))
        },
    }
};

const mapStateToProps = store => {
    return {
        photos: store.photos,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllsLikes)