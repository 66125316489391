import React, {Component} from 'react';
import './../pages/Profile.css';
import SmallHeart from "../assets/icon/small-heart.svg";
import OnWall from '../assets/icon/on-wall.svg';
import OnHistory from '../assets/icon/on-history.svg';
import DeletePhotoIcon from '../assets/icon/delete_photo.svg';

import Close from '../assets/icon/close.svg';
import Open from '../assets/icon/open.svg';

import { connect } from 'react-redux'
import {onWall} from "../actions/UserActions";
import {onHistory, deletePhoto} from "../actions/PhotosActions";
import FillSmallHeart from '../assets/icon/fill-small-heart.svg';
import LazyLoad from 'react-lazy-load';
import DeletePhoto from "./modals/DeletePhoto";

class UserProfilePhotos extends Component{

    state ={
        isShowDeletePhotoModal: false,
        photoId: null
    };

     handlerSendWall = (url, gender) => {
        this.props.onWallAction(url, gender);
    };

     handlerSendHistory = (urlPhoto, photoId) => {
         window.scrollTo(0,0);
         this.props.onHistoryAction(urlPhoto, photoId);
    };

    handleOpen = (e) => {
        const open = e.target.parentNode.parentNode;
        const close = e.target.parentNode.parentNode.parentNode.childNodes[1];
        open.classList.add('passive');
        close.classList.remove('passive');
    };

    handleClose = (e) => {
        const close = e.target.parentNode.parentNode;
        const open = e.target.parentNode.parentNode.parentNode.childNodes[0];
        close.classList.add('passive');
        open.classList.remove('passive');
    };

    /**
     * Удалить фото
     */
    handlerDeletePhoto = (photoId) => {
        this.props.deletePhotoAction(photoId);
    };

    /**
     * Хендлер для показа модалки удаления фото
     */
    handlerDeletePhotoOpen = (photoId) => {
        this.setState({isShowDeletePhotoModal: true});
        this.setState({photoId: photoId});
    };

    /**
     * Хендлер для закрытия модалки удаления фото
     */
    handlerDeletePhotoClose = () => {
        this.setState({isShowDeletePhotoModal: false})
    };

    render() {
        const {userProfilePhotos} = this.props;
        const {photoId, isShowDeletePhotoModal} = this.state;
        return (
            userProfilePhotos !== undefined && userProfilePhotos.map( (userProfilePhoto) => {
                return (
                    <div className="WrapperItem" key={userProfilePhoto.id}>
                        <div className="NameTopUser">
                    <span className="ProfileWrapperText">
                        <span className="ProfileText"></span>
                    </span>
                            <div className="WrapperHeart">
                                <div className="HeartCounter">
                                    <img src={ userProfilePhoto.likesCount !== 0 ? SmallHeart : FillSmallHeart } alt="Сердце"/>
                                </div>
                                <div className="Count">
                                    {userProfilePhoto.likesCount}
                                </div>
                            </div>
                        </div>
                        <div className="ProfileContainer">
                            <div className="ProfileContainer__Padding">
                                <div className="Open" onClick={this.handleOpen}>
                                    <img src={ Open } alt="Открыть" />
                                </div>
                            </div>
                            <div className="ProfileContainer__Padding passive">
                                <div className="ProfileSend">
                                    <div className="ProfileSendWall" onClick={() =>
                                        this.handlerSendWall(
                                            userProfilePhoto.onWallPath,
                                            this.props.user.userFromBackend.gender
                                        )}>
                                        <img src={ OnWall } alt="На стену"/>
                                        <div className="ProfileContainer__Padding__Name">На стену</div>
                                    </div>
                                    <div className="ProfileSendHistory" onClick={() =>
                                        this.handlerSendHistory(
                                            userProfilePhoto.url,
                                            userProfilePhoto.id
                                        )}>
                                        <img src={ OnHistory } alt="В историю"/>
                                        <div className="ProfileContainer__Padding__Name">В историю</div>
                                    </div>
                                    <div className="ProfileClose" onClick={(e) => this.handleClose(e)}>
                                        <img src={ Close } alt="Закрыть"/>
                                    </div>
                                </div>
                            </div>
                            <div className="Wrapper_Delete_Photo">
                                <div className="Delete_Photo"
                                     onClick={() => this.handlerDeletePhotoOpen(userProfilePhoto.id)}>
                                    <img src={ DeletePhotoIcon } alt="Удалить фото"/>
                                </div>
                            </div>
                            <LazyLoad
                                offsetVertical={300} >
                                <img className="TopImage TopPhotos__img"
                                     src={userProfilePhoto.url}
                                     width={userProfilePhoto.width}
                                     height={userProfilePhoto.height}
                                     alt=""/>
                            </LazyLoad>
                        </div>
                        <DeletePhoto
                            photoId={photoId}
                            isOpen={isShowDeletePhotoModal}
                            handlerClose={this.handlerDeletePhotoClose}
                            handlerChange={photoId => this.handlerDeletePhoto(photoId)}
                            title="Точно удалить фото?"
                            color="#FFFFFF"
                        />
                    </div>
                );
            })
        );
    }
}

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onWallAction: (url, gender) => {
            dispatch(onWall(url, gender))
        },
        onHistoryAction: (urlPhoto, photoId) => {
            dispatch(onHistory(urlPhoto, photoId))
        },
        deletePhotoAction: (photoId) => {
            dispatch(deletePhoto(photoId))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfilePhotos)
