import React, {Component} from 'react';
import './Premium.css';
import bigLaurel from './../assets/icon/big-laurel.svg';
import Title from './../components/Title';
import UnixTimeTitle from './../components/UnixTimeTitle';
import LargeButton from './../components/LargeButton';
import { connect } from 'react-redux'
import {payPremium, repost} from "../actions/UserActions";
import {isIOS} from 'react-device-detect';

class Premium extends Component{

    handlerPayPremium = () => {
        this.props.payPremiumAction(1);
    };

    handlerRepost = () => {
        this.props.repostAction();
    };

    render() {
        return (
            <div className="PaddingToTop">
                <div className="WrapperPremium">
                    <div className="Premium">
                        <div className="Wrapper_img">
                            <img src={bigLaurel} alt=""/>
                        </div>
                        <Title name="Что дает VIP-статус?"/>
                        <ul className="PremiumAdvantages">
                            <li>- Бесплатный переход в профили </li>
                            <li>пользователей.</li>
                            {
                                isIOS ? '' :
                                    <li>- Скидка 30% на все подарки.</li>
                            }
                        </ul>
                        {
                            this.props.user.userFromBackend.isVip === false ? (
                                isIOS ?
                                <LargeButton
                                    name ="Получить премиум на 1 день"
                                    action = { () => this.handlerRepost()}
                                />
                                :
                                <LargeButton
                                    name ="Купить VIP на неделю за 75 руб"
                                    disabled={this.props.user.disabledButtonPremium}
                                    action = {this.handlerPayPremium}
                                />
                            ) :
                                <UnixTimeTitle name="Премиум подключен до "
                                       unixTime={this.props.user.userFromBackend.vipStartTime +
                                       this.props.user.userFromBackend.vipDuration * 3600 * 24}/>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        payPremiumAction: (premiumId) => {
            dispatch(payPremium(premiumId))
        },
        repostAction: () => {
            dispatch(repost())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Premium)