import React from 'react';

function MobileSvg(props) {
    return (
        <svg width="170" height="145" viewBox="0 0 170 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="118.5" cy="85.5" r="36.5" fill="white" fillOpacity="0.1"/>
            <rect x="49.5" y="1.5" width="79" height="142" rx="8.5" stroke="#3E2F41" strokeWidth="3"/>
            <rect x="60.5" y="28.5" width="57" height="61" rx="4.5" stroke="#3E2F41" strokeWidth="3"/>
            <path d="M164.957 26.1215C164.577 21.9442 161.621 18.9136 157.921 18.9136C155.455 18.9136 153.198 20.2401 151.928 22.3663C150.67 20.2126 148.505 18.9131 146.079 18.9131C142.38 18.9131 139.423 21.9437 139.044 26.121C139.014 26.3055 138.89 27.2765 139.265 28.8601C139.804 31.1443 141.049 33.2219 142.864 34.867L151.922 43.087L161.136 34.8675C162.951 33.2219 164.196 31.1448 164.735 28.8601C165.11 27.277 164.987 26.306 164.957 26.1215Z" fill="#3E2F41"/>
            {/*<path d="M168.974 15.713L168.977 15.7469L168.983 15.7804C168.985 15.7932 169.069 16.3329 168.844 17.2886L168.844 17.2889C168.519 18.6633 167.769 19.9247 166.656 20.934L160.952 26.0227L155.347 20.9366L155.347 20.9361C154.233 19.9268 153.481 18.6641 153.156 17.2889L153.156 17.2887C152.93 16.3319 153.015 15.793 153.017 15.7827L153.023 15.7478L153.026 15.7126C153.251 13.2294 154.957 11.6321 156.901 11.6321C158.203 11.6321 159.384 12.3239 160.087 13.5273L160.941 14.9886L161.809 13.5356C162.515 12.354 163.758 11.6325 165.099 11.6325C167.043 11.6325 168.749 13.2297 168.974 15.713Z" stroke="white" strokeWidth="2"/>*/}
            <line x1="50" y1="128" x2="129" y2="128" stroke="#3E2F41" strokeWidth="2"/>
            <defs>
                <clipPath id="clip0">
                    <rect width="51.3194" height="51.3194" fill="white" transform="translate(81 75.8175) rotate(-25.159)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MobileSvg;