import React from 'react';
import { Route } from 'react-router-dom'
import SameFooter from './../layouts/footer/SameFooter';

const renderMergedProps = (Component, ...props) => {
    const finalProps = Object.assign({}, ...props);
    return (
        <SameFooter {...finalProps} >
            <Component {...finalProps} />
        </SameFooter>
    );
}

const SameFooterRouter = ({ component: Component, ...props }) => {
    return (
        <Route {...props} render={matchProps => {
            return renderMergedProps(Component, matchProps, props);
        }} />
    )
};

export default SameFooterRouter;