import * as VKConnect from '@vkontakte/vkui-connect';
import VKConnectPromise from '@vkontakte/vkui-connect-promise';
import { Base64 } from 'js-base64';
import {getUserPhotosFromBackend} from "./PhotosActions";
// import VKConnect from '@vkontakte/vkui-connect-mock';


import API from '../plugins/axios'
import {getCurrentUserFilter} from './FilterActions';

const GET_USER_FROM_VK_REQUEST = 'GET_USER_FROM_VK_REQUEST';
const GET_USER_FROM_VK_SUCCESS = 'GET_USER_FROM_VK_SUCCESS';
const GET_USER_FROM_BACKEND_REQUEST = 'GET_USER_FROM_BACKEND_REQUEST';
const GET_USER_FROM_BACKEND_SUCCESS = 'GET_USER_FROM_BACKEND_SUCCESS';

const SET_USER_REQUEST = 'SET_USER_REQUEST';
const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

const IS_FIRST_TIME = 'IS_FIRST_TIME';
const GET_USER_FROM_VK_BY_ID = 'GET_USER_FROM_VK_BY_ID';
const GET_FAVORITE_STATUS_REQUEST = 'GET_FAVORITE_STATUS_REQUEST';
const GET_FAVORITE_STATUS_SUCCESS = 'GET_FAVORITE_STATUS_SUCCESS';
const SET_FAVORITE_STATUS_REQUEST = 'SET_FAVORITE_STATUS_REQUEST';
const SET_FAVORITE_STATUS_SUCCESS = 'SET_FAVORITE_STATUS_SUCCESS';
const SAVE_SETTING_REQUEST = 'SAVE_SETTING_REQUEST';
const SAVE_SETTING_SUCCESS = 'SAVE_SETTING_SUCCESS';
const CLOSE_SETTING_CHANGE = 'CLOSE_SETTING_CHANGE';
const CLOSE_START_POPUP = 'CLOSE_START_POPUP';

const SET_PREMIUM_FOR_IPHONE_REQUEST = 'SET_PREMIUM_FOR_IPHONE_REQUEST';
const SET_PREMIUM_FOR_IPHONE_SUCCESS = 'SET_PREMIUM_FOR_IPHONE_SUCCESS';

const SAVE_SIGN = 'SAVE_SIGN';

const GET_LAVKA_LIKES_REQUEST = 'GET_LAVKA_LIKES_REQUEST';
const GET_LAVKA_LIKES_SUCCESS = 'GET_LAVKA_LIKES_SUCCESS';
const GET_PREMIUM_REQUEST = 'GET_PREMIUM_REQUEST';
const GET_PREMIUM_SUCCESS = 'GET_PREMIUM_SUCCESS';
const PAY_PREMIUM_REQUEST = 'PAY_PREMIUM_REQUEST';
const PAY_PREMIUM_SUCCESS = 'PAY_PREMIUM_SUCCESS';

const USER_WALL_SEND_SUCCESS = 'USER_WALL_SEND_SUCCESS';
const USER_WALL_SEND_ERROR = 'USER_WALL_SEND_ERROR';
const USER_WALL_SEND_CLOSE = 'USER_WALL_SEND_CLOSE';
const USER_WALL_SEND_REQUEST = 'USER_WALL_SEND_REQUEST';

const ENABLED_BUTTON_PREMIUM = 'ENABLED_BUTTON_PREMIUM';
const DISABLED_BUTTON_PREMIUM = 'DISABLED_BUTTON_PREMIUM';

const ENABLED_BUTTON_LAVKA_LIKE = 'ENABLED_BUTTON_LAVKA_LIKE';
const DISABLED_BUTTON_LAVKA_LIKE = 'DISABLED_BUTTON_LAVKA_LIKE';

const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

const SET_AGE = 'SET_AGE';
const SET_GENDER = 'SET_GENDER';

const CLOSE_PUSH_SCREEN_REQUEST = 'CLOSE_PUSH_SCREEN_REQUEST';
const CLOSE_PUSH_SCREEN_SUCCESS = 'CLOSE_PUSH_SCREEN_SUCCESS';
const CLOSE_PUSH_SCREEN_ERROR = 'CLOSE_PUSH_SCREEN_ERROR';

const CLOSE_PRESENT_DELIVERED_MODAL_REQUEST = 'CLOSE_PRESENT_DELIVERED_MODAL_REQUEST';
const CLOSE_PRESENT_DELIVERED_MODAL_SUCCESS = 'CLOSE_PRESENT_DELIVERED_MODAL_SUCCESS';
const CLOSE_PRESENT_DELIVERED_MODAL_ERROR = 'CLOSE_PRESENT_DELIVERED_MODAL_ERROR';

/**
 * Впервые ли пользователь на сайте
 * есть ли он в БД
 * @returns {Function}
 */
export function isFirstTime() {
    return async (dispatch,getState) => {
        let sign = getState().user.sign;
        await API({
            url:'/user/is-first-time',
            method: 'post',
            data: {
              'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: IS_FIRST_TIME,
                payload: response.data.status,
            });
        }).catch(function (error) {
            console.log(error)
        });
    }

}

/**
 * Получение информации пользователя из VK
 * @returns {Function}
 */
export function getUserFromVk() {
    return  dispatch => {
         dispatch({
            type: GET_USER_FROM_VK_REQUEST,
        });
        VKConnectPromise.send('VKWebAppGetUserInfo', {})
            .then( (response) => {
                let user = response.data;
                dispatch({
                    type: GET_USER_FROM_VK_SUCCESS,
                    payload: user,
                });
                // Впервые ли пользователь на сайте
                // есть ли он в БД
                dispatch(isFirstTime());
                dispatch(getUserFromBackend());
                dispatch(getCurrentUserFilter());
            })
            .catch( (response) => {
                console.log(response.data)
            });
    }
}

/**
 * Получение информации о пользователе из нашей БД
 * @returns {Function}
 */
export function getUserFromBackend() {
    return async (dispatch,getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: GET_USER_FROM_BACKEND_REQUEST,
        });
        await API({
            url:'/user/get-user',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: GET_USER_FROM_BACKEND_SUCCESS,
                payload: response.data,
            });
        }).catch(function (error) {
            console.log(error)
        });
    }
}

export function setUser(isMale, age) {
    return   (dispatch,getState) => {
         dispatch({
            type: IS_FIRST_TIME,
            payload: false,
        });
        dispatch({
            type: SET_USER_REQUEST,
        });
        let sign = getState().user.sign;
        let gender = isMale ? 2 : 1;
         API({
            url:'/user/set-user',
            method: 'put',
            data: {
                "sign": sign,
                "gender": gender,
                "age": age
            }
        }).then(function (response) {
            dispatch({
                type: SET_USER_SUCCESS,
                payload: response.data
            });
        }).catch(function (error) {
            console.log(error);
        });
    }
}

/**
 * Получение информации о пользователе по id
 * @param userId
 * @returns {Function}
 */
export function getUserById(userId) {
    return (dispatch, getState) => {
        let sign = getState().user.sign;
        API({
            url:'/user/get-from-vk',
            method: 'post',
            data: {
                'sign': sign,
                'id': userId
            },
        }).then(function (response) {
            dispatch({
                type: GET_USER_FROM_VK_BY_ID,
                payload: response.data,
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }
}

/**
 * Получение статуса В фавориты
 * @param anotherUserProfileId
 * @returns {Function}
 */
export function getFavoriteStatus(anotherUserProfileId) {
    return (dispatch, getState) => {
        dispatch({
            type: GET_FAVORITE_STATUS_REQUEST,
        });
        let sign = getState().user.sign;
        API({
            url:'/favorite/get-favorite-checked',
            method: 'post',
            data: {
                'sign': sign,
                'anotherUserProfileId': anotherUserProfileId,
            },
        }).then(function (response) {
            dispatch({
                type: GET_FAVORITE_STATUS_SUCCESS,
                payload: response.data.status,
            });
        }).catch(function (error) {
                console.log(error);
            });
    }
}

export function setFavoriteStatus(anotherUserProfileId) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_FAVORITE_STATUS_REQUEST,
        });
        let sign = getState().user.sign;
        let favoriteStatus = getState().user.favoriteStatus;

        API({
            url:'/favorite/set-favorite-checked',
            method: 'post',
            data: {
                'sign': sign,
                'anotherUserProfileId': anotherUserProfileId,
                'favoriteStatus': favoriteStatus,
            },
        }).then(function (response) {
            dispatch({
                type: SET_FAVORITE_STATUS_SUCCESS,
                payload: response.data.status,
            });
        }).catch(function (error) {
                console.log(error);
            });
    }
}

export function saveSetting(isAvatarToTop, isShowPhotos) {
    return (dispatch,getState) => {
        dispatch({
            type: SAVE_SETTING_REQUEST,
        });
        let sign = getState().user.sign;

        API({
            url:'/user/save-setting',
            method: 'post',
            data: {
                'sign': sign,
                'isAvatarToTop': isAvatarToTop,
                'isShowPhotos': isShowPhotos,
            },
        }).then(function (response) {
            dispatch({
                type: SAVE_SETTING_SUCCESS,
                payload: response.data,
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }
}

/**
 * Отправка фотографии на стену пользователя
 * @param url
 * @param gender
 * @returns {Function}
 */
export function onWall(url,gender) {
    return (dispatch) => {
        dispatch({
            type: USER_WALL_SEND_REQUEST,
        });
        VKConnectPromise.send("VKWebAppShowWallPostBox", {
            "message": 'Участвую в конкурсе самых красивых ' + (gender === 2 ? 'парней': 'девушек') +
                ' ВКонтакте в приложении ТОПовые.\nГолосуйте за меня и сами участвуйте!\n' +
                'vk.com/app' + process.env.REACT_APP_VK_APP_ID
            ,
            "attachments": url
        }).then( () => {
            dispatch({
                type: USER_WALL_SEND_SUCCESS,
            });
        })
        .catch( () => {
            dispatch({
                type: USER_WALL_SEND_ERROR,
            });
        })
    }
}

/**
 * Закрытие модалки для отправки фото пользователю
 * @returns {Function}
 */
export function closeUserSendModal() {
    return (dispatch) => {
        dispatch({
            type: USER_WALL_SEND_CLOSE,
        });
    }
}

export function changeColorStatusBar(color) {
    return () => {
        VKConnect.subscribe(e => {
            switch (e.detail.type) {
                case 'VKWebAppSetViewSettingsFailed':
                    // console.log(e.detail.data);
                    break;
                default:
                    break;
            }
        });
        VKConnect.send("VKWebAppSetViewSettings",
            {"status_bar_style": "light",
                "action_bar_color": color });
    }
}

export function closeSettingChange() {
    return  (dispatch) => {
        dispatch({
            type: CLOSE_SETTING_CHANGE,
        });
    }
}

export function closeStartPopup() {
    return  (dispatch) => {
        dispatch(getUserPhotosFromBackend('/'));
        dispatch({
            type: CLOSE_START_POPUP,
        });
    }
}

export function setGender(isMale) {
    return dispatch => {
        let sex;
        if (isMale) {
            sex=2;
        } else {
            sex=1;
        }

        dispatch({
            type: SET_GENDER,
            payload: Number(sex),
        });
    }
}

export function setAge(age) {
    return dispatch => {
        dispatch({
            type: SET_AGE,
            payload: Number(age),
        });
    }
}

/**
 * Метод для сохранения подписи пользователя в редуксе
 * @param url
 * @returns {Function}
 */
export function checkSign(url) {
    return (dispatch) => {
        dispatch({
            type: SAVE_SIGN,
            payload: url
        });
    }
}

/**
 * Экшн для получения списка всех лайков для вывода на странице lavka-likes
 * @returns {Function}
 */
export function getLavkaLikes() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_LAVKA_LIKES_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/user/get-lavka-like',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: GET_LAVKA_LIKES_SUCCESS,
                payload: response.data,
            });
        }).catch(function (error) {
            console.log(error)
        });
    }
}


export function getPremium() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_PREMIUM_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/user/get-premium',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: GET_PREMIUM_SUCCESS,
                payload: response.data,
            });
        }).catch(function (error) {
            console.log(error)
        });
    }
}

/**
 * Экшн для добавления лайков
 * @param lavkalikeId
 * @returns {Function}
 */
export function payLavkaLikes(lavkalikeId) {
    return (dispatch,getState) => {
        dispatch({
            type: DISABLED_BUTTON_LAVKA_LIKE,
        });
        let sign = getState().user.sign;
        API({
            url:'/order/create-order',
            method: 'post',
            data: {
                'sign': sign,
                'entity_name': 'LavkaLikes',
                'entity_id': lavkalikeId,
            },
        }).then(function (response) {
            var order_id = response.data.order_id;
            var amount = response.data.amount;
            var ts = + new Date();
            var merchant_data = Base64.encode(JSON.stringify({"amount":amount,"currency":"RUB","order_id":order_id,"ts":ts}));

            API({
                url:'/order/get-vk-pay-sign',
                method: 'post',
                data: {
                    'sign': sign,
                    'data': merchant_data,
                },
            }).then(function (response) {

                merchant_data = response.data.merchant_data;
                var merchant_sign = response.data.merchant_sign;
                var description = ' описание';
                var app_data = 'amount='+amount+
                    'data={"currency":"RUB","merchant_data":"'+merchant_data+
                    '","merchant_sign":"'+merchant_sign+'","order_id":"'+
                    order_id+'","ts":'+ts+'}description='+description+
                    'merchant_id='+process.env.REACT_APP_MERCHANT_ID+'version=2';

                API({
                    url:'/order/get-vk-pay-app-sign',
                    method: 'post',
                    data: {
                        'data': app_data,
                        'sign': sign,
                    },
                }).then(function (response) {
                    VKConnectPromise.send("VKWebAppOpenPayForm",
                        {"app_id": Number(process.env.REACT_APP_VK_APP_ID), "action": "pay-to-service", "params": {
                                "amount": amount,
                                "description": description,
                                "action": "pay-to-service",
                                "merchant_id": process.env.REACT_APP_MERCHANT_ID,
                                "version": 2,
                                "sign": response.data.app_sign,
                                "data": {
                                    "currency": "RUB",
                                    "merchant_data": merchant_data,
                                    "merchant_sign": merchant_sign,
                                    "order_id": order_id,
                                    "ts": ts,
                                }
                            }
                        }).then(function () {
                        //При успехе разблокируем кнопку
                        dispatch({
                            type: ENABLED_BUTTON_LAVKA_LIKE,
                        });
                    }).catch(function () {
                        //При ошибке разблокируем кнопку
                        dispatch({
                            type: ENABLED_BUTTON_LAVKA_LIKE,
                        });
                    });
                })
            })

        }).catch(function (error) {
            console.log(error)
        });
    }
}

/**
 * Экшн для оплаты премиума
 * @param premiumId
 * @returns {Function}
 */
export function payPremium(premiumId) {
    return (dispatch,getState) => {
        dispatch({
            type: DISABLED_BUTTON_PREMIUM,
        });
        let sign = getState().user.sign;
        API({
            url:'/order/create-order',
            method: 'post',
            data: {
                'sign': sign,
                'entity_name': 'Premium',
                'entity_id': premiumId,
            },
        }).then(function (response) {
            var order_id = response.data.order_id;
            var amount = response.data.amount;
            var ts = + new Date();
            var merchant_data = Base64.encode(JSON.stringify({"amount":amount,"currency":"RUB","order_id":order_id,"ts":ts}));

            API({
                url:'/order/get-vk-pay-sign',
                method: 'post',
                data: {
                    'sign': sign,
                    'data': merchant_data,
                },
            }).then(function (response) {

                merchant_data = response.data.merchant_data;
                var merchant_sign = response.data.merchant_sign;
                var description = ' описание';
                var app_data = 'amount=' + amount +
                    'data={"currency":"RUB","merchant_data":"' + merchant_data + '","merchant_sign":"' + merchant_sign + '","order_id":"' +
                    order_id + '","ts":' + ts + '}description=' + description +
                    'merchant_id=' + process.env.REACT_APP_MERCHANT_ID + 'version=2';

                API({
                    url: '/order/get-vk-pay-app-sign',
                    method: 'post',
                    data: {
                        'data': app_data,
                        'sign': sign,
                    },
                }).then(function (response) {
                    VKConnectPromise.send("VKWebAppOpenPayForm", {"app_id": Number(process.env.REACT_APP_VK_APP_ID), "action": "pay-to-service", "params": {
                            "amount": amount,
                            "description": description,
                            "action": "pay-to-service",
                            "merchant_id": process.env.REACT_APP_MERCHANT_ID,
                            "version": 2,
                            "sign": response.data.app_sign,
                            "data": {
                                "currency": "RUB",
                                "merchant_data": merchant_data,
                                "merchant_sign": merchant_sign,
                                "order_id": order_id,
                                "ts": ts,
                            }
                        }
                    }).then( function () {
                        setTimeout(() => {
                            let sign = getState().user.sign;
                            dispatch({
                                type: PAY_PREMIUM_REQUEST,
                            });
                            API({
                                url:'/user/pay-premium',
                                method: 'post',
                                data: {
                                    'sign': sign
                                },
                            }).then(function (response) {
                                dispatch({
                                    type: PAY_PREMIUM_SUCCESS,
                                    payload: response.data,
                                });
                            }).catch(function (error) {
                                console.log(error)
                            });
                        },2000);
                    }).catch(function () {
                        //При ошибке разблокируем кнопку
                        dispatch({
                            type: ENABLED_BUTTON_PREMIUM,
                        });
                    });
                });
            })
        });
    }
}

export function repost() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PREMIUM_FOR_IPHONE_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url: '/user/set-premium',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: SET_PREMIUM_FOR_IPHONE_SUCCESS,
                payload: response.data
            });
        }).catch(function (error) {
            console.log(error)
        });
    }
}

/**
 * Разрешение пуша пользователем
 * @returns {Function}
 */
export function allowPush() {
    return (dispatch, getState) => {
        VKConnectPromise.send('VKWebAppAllowNotifications', {})
            .then( () => {
                let sign = getState().user.sign;
                dispatch({
                    type: CLOSE_PUSH_SCREEN_REQUEST,
                });
                API({
                    url: '/user/close-push-screen',
                    method: 'post',
                    data: {
                        'sign': sign
                    },
                }).then(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_SUCCESS,
                    });
                    // dispatch(getUserFromVk());
                }).catch(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_ERROR,
                    });
                });
            })
            .catch( () => {
                let sign = getState().user.sign;
                dispatch({
                    type: CLOSE_PUSH_SCREEN_REQUEST,
                });
                API({
                    url: '/user/close-push-screen',
                    method: 'post',
                    data: {
                        'sign': sign
                    },
                }).then(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_SUCCESS,
                    });
                    // dispatch(getUserFromVk());
                }).catch(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_ERROR,
                    });
                });
            })
    }
}

/**
 * Запрет пуша пользователем
 * @returns {Function}
 */
export function notAllowPush() {
    return (dispatch, getState) => {
        VKConnectPromise.send('VKWebAppDenyNotifications', {})
            .then( ()=> {
                let sign = getState().user.sign;
                dispatch({
                    type: CLOSE_PUSH_SCREEN_REQUEST,
                });
                API({
                    url: '/user/close-push-screen',
                    method: 'post',
                    data: {
                        'sign': sign
                    },
                }).then(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_SUCCESS,
                    });
                    // dispatch(getUserFromVk());
                }).catch(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_ERROR,
                    });
                });
            })
            .catch( () => {
                let sign = getState().user.sign;
                dispatch({
                    type: CLOSE_PUSH_SCREEN_REQUEST,
                });
                API({
                    url: '/user/close-push-screen',
                    method: 'post',
                    data: {
                        'sign': sign
                    },
                }).then(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_SUCCESS,
                    });
                    // dispatch(getUserFromVk());
                }).catch(function () {
                    dispatch({
                        type: CLOSE_PUSH_SCREEN_ERROR,
                    });
                });
            })
    }
}

/**
 * Удалении аккаунта пользователя
 * @returns {Function}
 */
export function deleteAccount() {
    return (dispatch, getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: DELETE_ACCOUNT_REQUEST,
        });
        API({
            url: '/user/delete-account',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: DELETE_ACCOUNT_SUCCESS,
                payload: response.data
            });
            dispatch(getUserFromVk());
        }).catch(function (error) {
            dispatch({
                type: DELETE_ACCOUNT_ERROR,
            });
        });
    }
}

/**
 * Закрытие модального окна
 * @returns {Function}
 */
export function closePresentDeliveredModal() {
    return (dispatch, getState) => {
        let sign = getState().user.sign;
        dispatch({
            type: CLOSE_PRESENT_DELIVERED_MODAL_REQUEST,
        });
        API({
            url: '/user/present-delivered',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: CLOSE_PRESENT_DELIVERED_MODAL_SUCCESS,
                payload: response.data
            });
            dispatch(getUserFromVk());
        }).catch(function (error) {
            dispatch({
                type: CLOSE_PRESENT_DELIVERED_MODAL_ERROR,
            });
        });
    }
}