import React,{Component} from 'react';
import SmallHeart from "../assets/icon/small-heart.svg";
import FillSmallHeart from "../assets/icon/fill-small-heart.svg";
import InfiniteScroll from 'react-infinite-scroll-component'
import LazyLoad from 'react-lazy-load'

const TopPhotos = (props) => {
    const {topPhotos, user, handlerGoToUser,
        scrollToRefresh, handleAddLike} = props;

    return (
        <InfiniteScroll
            dataLength={topPhotos.length}
            next={scrollToRefresh()}
            hasMore={true}
            loader={<h4></h4>}
        >

            {
                topPhotos.map( (topPhoto) => {
                    return (
                        <div className="WrapperItem" key={topPhoto.id}>
                            <div className="NameTopUser">
                                {
                                    Number(topPhoto.user.gender) === Number(user.userFromBackend.gender) ?
                                        ( Number(topPhoto.user.id) === Number(user.userFromBackend.id)
                                                ?
                                                <span onClick={() => handlerGoToUser('')}>
                                    {topPhoto.user.first_name}
                                </span>
                                                :
                                                <span>
                                    {topPhoto.user.first_name}
                                </span>
                                        ):
                                        <span onClick={() => handlerGoToUser(topPhoto.user.id)}>
                                    {topPhoto.user.first_name}
                                </span>
                                }
                                <div className="WrapperHeart" onClick={() => handleAddLike(topPhoto.id)}>
                                    <div className="HeartCounter">
                                        <img src={ topPhoto.userLikeThisPhoto ?
                                             SmallHeart : FillSmallHeart }
                                             alt="Сердце"/>
                                    </div>
                                    <div className="Count">
                                        {topPhoto.likesCount}
                                    </div>
                                </div>
                            </div>
                            <LazyLoad offsetVertical={300} >
                                <img className="TopImage TopPhotos__img"
                                     src={topPhoto.url}
                                     height={topPhoto.height}
                                     width={topPhoto.width} alt=""
                                     onClick={() => handleAddLike(topPhoto.id)}/>
                            </LazyLoad>
                        </div>
                    );
                })
            }
        </InfiniteScroll>
    );
}

export default TopPhotos;