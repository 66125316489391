import axios from 'axios';
// import { store } from './../store/configureStore';

const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_AXIOS_URL,
    withCredentials: false,
    headers: {
       'Accept': '*/*',
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*',
   }
});
// let token = localStorage.getItem('token');
// if (token !== null) {
//     const createSetAuthInterceptor = config => {
//         config.headers.Authorization = `Bearer ${token}`;
//
//         return config;
//     };
//
//     API.interceptors.request.use(createSetAuthInterceptor);
// }


export default API;