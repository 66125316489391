import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
    isFirstTime: null,
    anotherUserProfile: null,
    ownUserProfile: {
        id: null,
    },
    userFromBackend: {
        id: null,
        //Если не указан в профиле vk возраст
        age: null,
        //Если не указан в профиле vk пол
        gender: null,
        limitLikes: null,
        //Модальное окно, которое появится после покупки подарка
        isPresentDelivered: false,
    },
    needPremiumStatus: false,
    favoriteStatus: false,
    isLoading: false,
    sign: null,
    settingIsChange: false,
    diffNowTime: null,
    modalStartInfo: false,
    startTime: null,

    lavkaLikes: null,
    premium: null,

    userWallSendSuccess: false,
    userWallSendError: false,
    //блокировка кнопки на странице Премиум
    disabledButtonPremium: false,
    disabledButtonLavkaLike: false,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'SAVE_SIGN':
            return {...state, sign: action.payload};

        case 'GET_USER_FROM_VK_REQUEST':
            return {...state, isLoading: true};
        case 'GET_USER_FROM_VK_SUCCESS':
            return {...state, ownUserProfile: action.payload, isLoading: false};

        case 'GET_USER_FROM_BACKEND_REQUEST':
            return {...state, isLoading: true};
        case 'GET_USER_FROM_BACKEND_SUCCESS':
            return {...state, userFromBackend: action.payload, isLoading: false};

        case 'IS_FIRST_TIME':
            return {...state, isFirstTime: action.payload};
        case 'SET_USER_REQUEST':
            return {...state, isLoading: true};
        case 'SET_USER_SUCCESS':
            return {...state,
                userFromBackend: action.payload,
                startTime: action.payload.startTime,
                isLoading: false,
                modalStartInfo: true};
        case 'GET_USER_FROM_VK_BY_ID':
            return {...state, anotherUserProfile: action.payload};

        case 'GET_FAVORITE_STATUS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_FAVORITE_STATUS_SUCCESS':
            return {...state, favoriteStatus: action.payload, isLoading: false};

        case 'SET_FAVORITE_STATUS_REQUEST':
            return {...state, isLoading: true};
        case 'SET_FAVORITE_STATUS_SUCCESS':
            return {...state, favoriteStatus: action.payload, isLoading: false};

        case 'SAVE_SETTING_REQUEST':
            return {...state, isLoading: true};
        case 'SAVE_SETTING_SUCCESS':
            return {...state, userFromBackend: {
                    ...state.userFromBackend,
                    isAvatarToTop: action.payload.isAvatarToTop,
                    isShowPhotos: action.payload.isShowPhotos,
                }, settingIsChange:true, isLoading: false};
        case 'CLOSE_SETTING_CHANGE':
            return {...state, settingIsChange: false};
        case 'CLOSE_START_POPUP':
            return {...state, modalStartInfo: false};
        case 'SET_PREMIUM_FOR_IPHONE_REQUEST':
            return {...state, isLoading: true};
        case 'SET_PREMIUM_FOR_IPHONE_SUCCESS':
            return {...state, userFromBackend: { ...state.userFromBackend,
                    isVip: true,
                    vipStartTime: action.payload.vipStartTime,
                    vipDuration: action.payload.vipDuration,
                },
                isLoading: false,
            };
        case 'GET_DIFF_NOW_TIME':
            return {...state, diffNowTime: action.payload};

        case 'GET_LAVKA_LIKES_REQUEST':
            return {...state, isLoading: true};
        case 'GET_LAVKA_LIKES_SUCCESS':
            return {...state, lavkaLikes: action.payload, isLoading: false};
        case 'GET_PREMIUM_REQUEST':
            return {...state, isLoading: true};
        case 'GET_PREMIUM_SUCCESS':
            return {...state, premium: action.payload, isLoading: false};
        case 'PAY_PREMIUM_REQUEST':
            return {...state, isLoading: true};
        case 'PAY_PREMIUM_SUCCESS':
            return {...state,
                isLoading: false,
                userFromBackend: { ...state.userFromBackend,
                    isVip: action.payload.isVip,
                    vipStartTime: action.payload.vipStartTime,
                    vipDuration: action.payload.vipDuration}};

        case 'USER_WALL_SEND_REQUEST':
            return {...state, isLoading: true};
        case 'USER_WALL_SEND_SUCCESS':
            return {...state, userWallSendSuccess: true, isLoading: false};
        case 'USER_WALL_SEND_ERROR':
            return {...state, isLoading: false};
        case 'USER_WALL_SEND_CLOSE':
            return {...state,
                userWallSendSuccess: false,
                userWallSendError: false
            };

        case 'ENABLED_BUTTON_PREMIUM':
            return {...state, disabledButtonPremium: false};
        case 'DISABLED_BUTTON_PREMIUM':
            return {...state, disabledButtonPremium: true};

        case 'ENABLED_BUTTON_LAVKA_LIKE':
            return {...state, disabledButtonLavkaLike: false};
        case 'DISABLED_BUTTON_LAVKA_LIKE':
            return {...state, disabledButtonLavkaLike: true};

        case 'DELETE_ACCOUNT_REQUEST':
            return {...state, isLoading: true};
        case 'DELETE_ACCOUNT_SUCCESS':
            return {...state, isLoading: false, isFirstTime: true};
        case 'DELETE_ACCOUNT_ERROR':
            return {...state, isLoading: false};

        case 'SET_GENDER':
            return {...state,userFromBackend: { ...state.userFromBackend,
                    gender: action.payload,
                },};
        case 'SET_AGE':
            return {...state, userFromBackend: { ...state.userFromBackend,
                    age: action.payload,
                }};

        case LOCATION_CHANGE:
            // console.warn('LOCATION_CHANGE from your reducer',action)
            return state

        case 'CLOSE_PUSH_SCREEN_REQUEST':
            return {...state, isLoading: true};
        case 'CLOSE_PUSH_SCREEN_SUCCESS':
            return {...state, isLoading: false, userFromBackend: {
                    ...state.userFromBackend,
                    showPushScreen: false,
                }};
        case 'CLOSE_PUSH_SCREEN_ERROR':
            return {...state, isLoading: false, userFromBackend: {
                    ...state.userFromBackend,
                    showPushScreen: false,
                }};

        case 'CLOSE_PRESENT_DELIVERED_MODAL_REQUEST':
            return {...state, isLoading: true};
        case 'CLOSE_PRESENT_DELIVERED_MODAL_SUCCESS':
            return {...state, isLoading: false, userFromBackend: {
                    ...state.userFromBackend,
                    isPresentDelivered: action.payload.isPresentDelivered,
                }};
        case 'CLOSE_PRESENT_DELIVERED_MODAL_ERROR':
            return {...state, isLoading: false, userFromBackend: {
                    ...state.userFromBackend,
                    isPresentDelivered: action.payload.isPresentDelivered,
                }};

        default:
            return state
    }
}
