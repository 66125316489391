import React from 'react';
import './Title.css';
import PropTypes from 'prop-types';

function Title(props) {
    const style = {
        "fontSize": props.font,
        "fontWeight": props.weight,
        "color": props.color
    }
        return (
            <div className="Title" style={style}>
                { props.name }
            </div>
        );
}

export default Title;

Title.propTypes = {
    name: PropTypes.string.isRequired,
    font: PropTypes.number,
    weight: PropTypes.number,
    color: PropTypes.string
};