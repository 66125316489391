import React from 'react';
import './Header.css';
import back from './../assets/icon/back.svg';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { View,Panel, PanelHeader,HeaderButton } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

function Header(props) {

    /**
     * Хендлер для автоматической прокрутки скролла наверх при нажатии на хелер
     * (Tap to top)
     */
    const handleScrollToTop = () => {
        window.scrollTo(0,0);
    };

    const slug = props.match.url;
    let showBackButton;
    //Показываем кнопку назад только не на этих маршрутах
    if(slug === '/premium/') {
        showBackButton = false;
    }else {
        showBackButton = true;
    }

        return(
            <div onClick={handleScrollToTop} className="SingleHeader Header Header_No_Select">
                <div>
                    <View activePanel="panel1">
                        <Panel id="panel1">
                            <PanelHeader
                                left={<HeaderButton disabled>
                                    <div className="CabinetHeader">
                                        {
                                            showBackButton ?
                                                <div className="CabinetHeaderBack"
                                                     onClick={props.history.goBack}>
                                                    <img src={back} alt=""/>
                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                </HeaderButton>}
                            ><span className="Header__Name">{ props.name }</span>
                            </PanelHeader>
                        </Panel>
                    </View>
                </div>
            </div>
        );
}

export default withRouter(Header);

Header.propTypes = {
    name: PropTypes.string.isRequired,
}