const initialState = {
    all: [],
    iPresent: [],
    mePresent: [],
    isLoading: false,
    disabledButtonPresent: false
};

export function presentsReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_ALL_PRESENTS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_ALL_PRESENTS_SUCCESS':
            return {...state, all: action.payload, isLoading: false};

        case 'GET_OWN_PRESENTS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_OWN_PRESENTS_SUCCESS':
            return {...state, iPresent: action.payload.iPresent,mePresent: action.payload.mePresent, isLoading: false};

        case 'DISABLED_BUTTON_PRESENT':
            return {...state, disabledButtonPresent: true};
        case 'ENABLED_BUTTON_PRESENT':
            return {...state, disabledButtonPresent: false};

        default:
            return state
    }
}
