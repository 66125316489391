import React, {Component} from 'react';
import './AddPhoto.css';
import camera from './../assets/icon/camera.svg';
import Dropzone from 'react-dropzone';
import {uploadPhotos} from '../actions/PhotosActions';
import { connect } from 'react-redux'

class AddPhoto extends Component {
    constructor() {
        super();
        window.scrollTo(0,0);
    }

    render() {
        //Максимальный размер загружаемых фото 50М
        const maxSize = 50048576;

        this.onDrop = (files) => {
            this.props.uploadPhotosAction(files);
        };

        return (
            <Dropzone
                onDrop={this.onDrop}
                minSize={0}
                maxSize={maxSize}
                accept={'image/*'}
            >
                {({getRootProps, getInputProps}) => (
                    <section className="container">
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="AddPhoto">
                                <span className="AddPhoto_Span">
                                    Добавьте Фото для ТОПа
                                </span>
                                <img src={camera} alt="Иконка фильтра"/>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadPhotosAction: (files) => {
            dispatch(uploadPhotos(files))
        }
    }
};

export default connect(
    null,
    mapDispatchToProps
)(AddPhoto)