import React from 'react';
import Modal from 'react-modal';
import './Popup.css';
import Title from './../Title';
import bad from "../../assets/icon/error.svg";
import PropTypes from 'prop-types';
import LargeButton from "../LargeButton";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        background            : 'transparent',
    },
    overlay: {
        zIndex                : '2000'
    }
};

Modal.setAppElement('#root');

function DeletePhoto(props) {
        const {isOpen, handlerClose, handlerChange, title, color, photoId} = props;
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={handlerClose}
                    style={customStyles}
                >
                    <div className="Popup" style={{background: '#5F4B63'}}>
                        <div className="ModalClose" style={{color: color}} onClick={handlerClose}>&times;</div>
                        <div className="Popup__img">
                            <img src={bad} alt=""/>
                        </div>
                        <Title name={title} color={color}/>

                        <div className="DeleteAccount__WrapperButton">
                            <LargeButton name="Да"
                                         action={() => handlerChange(photoId)}
                                         style={{
                                             background: "#FF3E3E",
                                             border: "1px solid #FF3E3E",
                                             margin: " 5% 2% 0 2%"}}
                            />
                            <LargeButton name="Нет"
                                         action={handlerClose}
                                         style={{
                                             margin: " 5% 2% 0 2%"}}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
}

export default DeletePhoto;

DeletePhoto.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handlerClose: PropTypes.func,
    handlerChange: PropTypes.func,
    title: PropTypes.string,
    color: PropTypes.string
};