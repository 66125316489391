import React from 'react'
import './SetSex.css'
import Title from './../components/Title'
import SubTitle from './../components/SubTitle'
import LargeButton from './../components/LargeButton'
import { connect } from 'react-redux'
import Switch from './../components/Switch';
import {setGender, setUser} from "../actions/UserActions";

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMale: true,
        }
    }
    handlerSetGender = () => {
        this.setState(prevState => ({
            isMale: !prevState.isMale,
        }));
    };

    handlerSaveGender = () => {
        this.props.setGenderAction(this.state.isMale);
        this.props.setUserAction();
        this.props.history.replace('/');
    };

    render() {
        const {isMale} = this.state;
        return (
            <div className="StartWrapper">
                <Title name={"Вы не указали свой пол в контакте!"}/>
                <SubTitle name={
                    "Пожалуйста выберете его:"
                } />
                <div className="SetSex__SetGender">
                    <span>Мужской</span>
                    <Switch
                        isChecked={isMale}
                        action={this.handlerSetGender}
                    />
                    <span>Женский</span>
                </div>
                <LargeButton
                name={"Применить"}
                action={this.handlerSaveGender}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGenderAction: (isMale) => {
            dispatch(setGender(isMale))
        },
        setUserAction: () => {
            dispatch(setUser())
        },
    }
};

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Start)