import React from 'react';
import './PremiumButton.css';

function PremiumButton(props){
    const { name, disabled, action, style, throughPrice, premiumId} = props
        return (
            <button className="PremiumButton"
                    onClick={() => action(premiumId)}
                    disabled={disabled}
                    style={style}>
                <span>
                    { name }<span className="LineThrough">{throughPrice}</span>
                </span>
            </button>
        );
}

export default PremiumButton;