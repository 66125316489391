import React, {Component} from 'react';
import './CabinetHeader.css';
import { connect } from 'react-redux'
import {
    changeColorStatusBar
} from "../actions/UserActions";
import back from "../assets/icon/back.svg";
import { View,Panel, PanelHeader, HeaderButton } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

class CabinetHeader extends Component{

    componentDidMount() {
        this.props.changeColorStatusBarAction("#3A2B3D");
    }

    /**
     * Хендлер для автоматической прокрутки скролла наверх при нажатии на хелер
     * (Tap to top)
     */
    handleScrollToTop = () => {
        window.scrollTo(0,0);
    };

    render() {

        // let limitLikes = 10;
        // if ('userFromBackend' in this.props.user &&  this.props.user.userFromBackend &&
        //     this.props.user.userFromBackend.isVip === true) {
        //     limitLikes = 20;
        // }

        const slug = this.props.match.url;
        let showBackButton;
        //Показываем кнопку назад только не на этих маршрутах
        if(slug === '/' ||
            slug === '/profile-rating/' ||
            slug === '/rating/news/' ||
            slug === '/rating/likes/' ||
            slug === '/rating/alls/' ||
            slug === '/tops/day/1' ||
            slug === '/tops/day/2' ||
            slug === '/tops/week/1' ||
            slug === '/tops/week/2' ||
            slug === '/tops/month/1' ||
            slug === '/tops/month/2'
        ) {
            showBackButton = false;
        }else {
            showBackButton = true;
        }

        return (
            <div onClick={this.handleScrollToTop} className="Header_No_Select">
                <View activePanel="panel1">
                    <Panel id="panel1">
                        <PanelHeader
                            left={<HeaderButton disabled>
                                <div className="CabinetHeader">
                                    {
                                        showBackButton ?
                                            <div className="CabinetHeaderBack"
                                                 onClick={this.props.history.goBack}>
                                                <img src={back} alt=""/>
                                            </div>
                                            :
                                            ''
                                    }
                                    {/*<div className="HeartCounter">*/}
                                    {/*    <img src={ SmallHeart } alt="Количество лайков" />*/}
                                    {/*</div>*/}
                                    {/*<div className="Count">*/}
                                    {/*    { 'userFromBackend' in this.props.user &&*/}
                                    {/*    this.props.user.userFromBackend ?*/}
                                    {/*        this.props.user.userFromBackend.limitLikes : null}*/}
                                    {/*</div>*/}
                                    {/*<div className="TimerCounter">*/}
                                    {/*    {*/}
                                    {/*        ('userFromBackend' in this.props.user &&*/}
                                    {/*        this.props.user.userFromBackend ?*/}
                                    {/*            this.props.user.userFromBackend.limitLikes < limitLikes &&*/}
                                    {/*            this.props.user.userFromBackend.limitLikes >= 0*/}
                                    {/*            : null) ?*/}
                                    {/*            <Countdown*/}
                                    {/*                date={this.props.user.startTime +*/}
                                    {/*            (this.props.user.userFromBackend.isVip === true ?*/}
                                    {/*                (60*60*1000/2 + 1*60*1000): (60*60*1000 + 1*60*1000))}*/}
                                    {/*                key={this.props.user.userFromBackend.limitLikes}*/}
                                    {/*               renderer={({ minutes, seconds,completed }) => {*/}
                                    {/*                   if (seconds >= 0 && seconds < 10) {*/}
                                    {/*                       seconds = ('0' + seconds).slice(-2)*/}
                                    {/*                   }*/}
                                    {/*                   if (completed) {*/}
                                    {/*                       setTimeout(() => {*/}
                                    {/*                           this.handleUpdateStartTime()*/}
                                    {/*                       }, 500);*/}

                                    {/*                       return <span></span>;*/}
                                    {/*                   }else {*/}
                                    {/*                       return <span className="TimerWord"> +1(через {minutes}:{seconds})</span>*/}
                                    {/*                   }*/}
                                    {/*                   }}*/}
                                    {/*            /> : ''*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                    {/*{*/}
                                    {/*    isIOS ? '' :*/}
                                    {/*        <Link to="/lavka-likes/" className="PlusHeader">*/}
                                    {/*            <img src={ PlusHeader } alt="Плюс"/>*/}
                                    {/*        </Link>*/}
                                    {/*}*/}
                                </div>
                            </HeaderButton>}>
                        </PanelHeader>
                    </Panel>
                </View>
            </div>
            )
    }
}

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeColorStatusBarAction: (color) => {
            dispatch(changeColorStatusBar(color))
        },
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CabinetHeader)