import React, {Component} from 'react';
import './Presents.css';
import {Link} from "react-router-dom";
import vk from '../assets/icon/vk.svg'
import {getOwnPresents} from "../actions/PresentsActions";
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment/locale/ru';
import { Spinner } from '@vkontakte/vkui';

const MePresetsList = (props) => {
    const {presents, handlerGoToUser} = props;
    return (
        presents.map( present => {
            return (
                <div className="PresentsWrapper" key={present.id}>
                    <img className="PresentsIcon" src={present.present.url} alt="Это букет"/>
                    <div className="PresentTitle">
                        <div className="PresentUser"
                             onClick={() => handlerGoToUser(present.userWhoPresent.id)}>
                            {present.userWhoPresent.title}
                        </div>
                        {
                            present.userWhoPresent.vkId ?                         <div className="PresentDate">
                                Вам открыт профиль ВК
                            </div> : ''
                        }
                    </div>
                    {
                        present.userWhoPresent.vkId ?                     <div className="VkIcon">
                            <a href={'https://vk.com/id' + present.userWhoPresent.vkId}>
                                <img src={vk} alt="Лого vk"/>
                            </a>
                        </div> : ''
                    }
                </div>
            )
        })
    )
};

const IPresetsList = (props) => {
    const {presents, handlerGoToUser} = props;
    return (
        presents.map( present => {
            return (
                <div className="PresentsWrapper" key={present.id}>
                    <img className="PresentsIcon" src={present.present.url} alt=""/>
                    <div className="PresentTitle">
                        <div className="PresentUser"
                             onClick={() => handlerGoToUser(present.userWhichPresent.id)}>
                            {present.userWhichPresent.title}
                        </div>
                        <div className="PresentDate">
                            <Moment locale="ru" parse="YYYY-MM-DD" format={"DD MMM YYYY"}>{present.createdAt}</Moment>
                        </div>
                    </div>
                </div>
            )
        })
    )
};

class Presents extends Component{
    componentDidMount() {
        this.props.getOwnPresentsAction();
        window.scrollTo(0,0);
    }

     handlerGoToUser = (id) => {
        if(Number(id) === this.props.user.ownUserProfile.id) {
            this.props.history.push('/');
        }else {
            this.props.history.push('/' + id);
        }
    };

    render() {
        const { match: { params } } = this.props;
        const {slug} = params;

        const {iPresent, mePresent} = this.props.presents;

        let iPresentLink={};
        let mePresentLink={};
        const activeLink = {
            'color': 'white',
            'borderBottomColor': 'white'
        };
        const passiveLink = {
            'color': '#B3A7B6',
            'borderBottomColor': '#5A465E'
        };
        if (slug === 'iPresent') {
            iPresentLink = activeLink;
            mePresentLink = passiveLink;
        }else {
            iPresentLink = passiveLink;
            mePresentLink = activeLink;
        }

        if (this.props.presents.isLoading) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            );
        }

        if (slug === 'iPresent') {
            return (
                <div className="PaddingToTop PaddingToBottom">
                    <div className="Presents">
                        <div className="WrapperTop">
                            <div className="Presents_Navbar">
                                <Link to="/presents/iPresent" style={iPresentLink}
                                      className="Presents_GenderLinks" replace >
                                    <span>Вы подарили</span>
                                </Link>
                                <Link to="/presents/mePresent" style={mePresentLink}
                                      className="Presents_GenderLinks" replace >
                                    <span>Вам подарили</span>
                                </Link>
                            </div>
                        </div>
                        {
                            iPresent.length !== 0 ? <IPresetsList
                                presents={iPresent}
                                handlerGoToUser={ url => this.handlerGoToUser(url)}/> :
                                'Вы ещё ничего не подарили'
                        }
                    </div>
                </div>
            );
        }else {
            return (
                <div className="PaddingToTop PaddingToBottom">
                    <div className="Presents">
                        <div className="WrapperTop">
                            <div className="Presents_Navbar">
                                <Link to="/presents/iPresent" style={iPresentLink}
                                      className="Presents_GenderLinks" replace >
                                    <span>Вы подарили</span>
                                </Link>
                                <Link to="/presents/mePresent" style={mePresentLink}
                                      className="Presents_GenderLinks" replace >
                                    <span>Вам подарили</span>
                                </Link>
                            </div>
                        </div>
                        {
                            mePresent.length !== 0 ? <MePresetsList
                                presents={mePresent}
                                handlerGoToUser={ url => this.handlerGoToUser(url)}/> :
                                'Вам ещё ничего не подарили'
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        presents: store.presents,
        user: store.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOwnPresentsAction: () => {
            dispatch(getOwnPresents())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Presents)