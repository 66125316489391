import React from 'react';
import Modal from 'react-modal';
import './Popup.css';
import Title from './../Title'
import SubTitle from './../SubTitle'
import good from "../../assets/icon/good-upload.svg";
import bad from "../../assets/icon/error.svg";
import PropTypes from 'prop-types';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        background            : 'transparent',
    },
    overlay: {
        zIndex                : '2000'
    }
};

Modal.setAppElement('#root');

function Popup(props) {
        const {isOpen, handlerClose, title, subTitle, error, color} = props;
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={handlerClose}
                    style={customStyles}
                >
                    <div className="Popup" style={
                        error ? {background: '#5F4B63'} : {background: '#F6F5F7'}
                    }>
                        <div className="ModalClose" style={error ? {color: color} : {}} onClick={handlerClose}>&times;</div>
                        <div className="Popup__img">
                            {
                                error ? <img src={bad} alt=""/> :
                                    <img src={good} alt=""/>
                            }
                        </div>
                        <Title name={title} color={color}/>
                        <SubTitle
                            name={subTitle}
                        />

                    </div>
                </Modal>
            </div>
        );
}

export default Popup;

Popup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handlerClose: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    error: PropTypes.bool,
    color: PropTypes.string
};