import React from 'react';
import InputMask from 'react-input-mask';

class FilterInput extends React.Component {
    render() {
        const {placeholder, handleChange, value} = this.props;
        let format={
            '9': '[0-9]',
            '0': '[1-9]',
        }

        return (
            <div className="FilterInput__Wrapper">
                <InputMask
                    maskChar='_'
                    formatChars={format}
                    mask="09"
                    value={value}
                    placeholder={placeholder}
                    className="FilterInput__Input"
                    onChange={e => handleChange(e)}
                    type = "tel"
                >
                </InputMask>
            </div>
        );
    }
}

export default FilterInput;