const initialState = {
    userProfilePhotos: null,
    topPhotos: null,
    ratingPhotos: [],
    photoIsUpload: false,
    failUploadPhoto: false,
    myFavorites: null,
    allsLikes: null,
    iLike: null,
    youLike: null,
    isLoading: false,
    isAlls: false,
    counts: {
        isAlls: null,
        myFavorites: null,
        iLike: null,
        youLike: null,
        iPresents: null,
        youPresents: null
    },
    userHistorySendSuccess: false,
    userHistorySendError: false,
    currentPhotoid: null,
    //показывать попап с информацией что вы получите бесплатно vip на день если придёте завтра
    showPopupFreePremium: false,
};

export function photosReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_USER_PHOTOS_FROM_BACKEND_REQUEST':
            return {...state, isLoading: true};
        case 'GET_USER_PHOTOS_FROM_BACKEND_SUCCESS':
            return {...state,
                userProfilePhotos: action.payload.userProfilePhotos,
                counts: action.payload.counts,
                isLoading: false};

        case 'GET_OTHER_USER_PHOTOS_FROM_BACKEND_REQUEST':
            return {...state, isLoading: true};
        case 'GET_OTHER_USER_PHOTOS_FROM_BACKEND_SUCCESS':
            return {...state,
                userProfilePhotos: action.payload,
                isLoading: false};

        case 'GET_TOP_PHOTOS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_TOP_PHOTOS_SUCCESS':
            return {...state, topPhotos: action.payload, isLoading: false};

        case 'UPDATE_TOP_PHOTOS_WHEN_LIKE':
            return {...state, topPhotos: action.payload.photos};

        case 'GET_TOP_PHOTOS_ERROR':
            return {...state, isLoading: false};

        case 'UPDATE_TOP_PHOTOS_REQUEST':
            return {...state};
        case 'UPDATE_TOP_PHOTOS_SUCCESS':
            return {...state, topPhotos : state.topPhotos.concat(action.payload), isLoading: false};
        case 'UPDATE_TOP_PHOTOS_ERROR':
            return {...state};

        case 'GET_RATING_PHOTOS_REQUEST':
            return {...state, isLoading: true};
        case 'GET_RATING_PHOTOS_SUCCESS':
            return {...state, ratingPhotos: action.payload, isLoading: false};
        case 'GET_RATING_PHOTOS_ERROR':
            return {...state, isLoading: false};

        case 'UPLOAD_PHOTO_REQUEST':
            return {...state,
                isLoading: true
            };
        case 'UPLOAD_PHOTO_SUCCESS':
            return {...state,
                photoIsUpload: true,
                isLoading: false,
                userProfilePhotos: action.payload
            };
        case 'CLOSE_ADD_PHOTO_MODAL':
            return {...state, photoIsUpload: false};
        case 'UPLOAD_PHOTO_ERROR':
            return {...state, failUploadPhoto: true, isLoading: false,};
        case 'CLOSE_FAIL_UPLOAD_PHOTO_MODAL':
            return {...state, failUploadPhoto: false};

        case 'GET_MY_FAVORITES_REQUEST':
            return {...state, isLoading: true};
        case 'GET_MY_FAVORITES_SUCCESS':
            return {...state, myFavorites: action.payload, isLoading: false};
        case 'GET_MY_FAVORITES_ERROR':
            return {...state, isLoading: false};

        case 'UPDATE_MY_FAVORITES_REQUEST':
            return {...state};
        case 'UPDATE_MY_FAVORITES_SUCCESS':
            return {...state, myFavorites : state.myFavorites.concat(action.payload), isLoading: false};
        case 'UPDATE_MY_FAVORITES_ERROR':
            return {...state};

        case 'GET_YOU_LIKE_REQUEST':
            return {...state, isLoading: true};
        case 'GET_YOU_LIKE_SUCCESS':
            return {...state, youLike: action.payload, isLoading: false};
        case 'GET_YOU_LIKE_ERROR':
            return {...state, isLoading: false};

        case 'UPDATE_YOU_LIKE_REQUEST':
            return {...state};
        case 'UPDATE_YOU_LIKE_SUCCESS':
            return {...state, youLike : state.youLike.concat(action.payload), isLoading: false};
        case 'UPDATE_YOU_LIKE_ERROR':
            return {...state};

        case 'GET_I_LIKE_REQUEST':
            return {...state, isLoading: true};
        case 'GET_I_LIKE_SUCCESS':
            return {...state, iLike: action.payload, isLoading: false};
        case 'GET_I_LIKE_ERROR':
            return {...state, isLoading: false};

        case 'UPDATE_I_LIKE_REQUEST':
            return {...state};
        case 'UPDATE_I_LIKE_SUCCESS':
            return {...state, iLike : state.iLike.concat(action.payload), isLoading: false};
        case 'UPDATE_I_LIKE_ERROR':
            return {...state};

        case 'GET_ALLS_LIKES_REQUEST':
            return {...state, isLoading: true};
        case 'GET_ALLS_LIKES_SUCCESS':
            return {...state, allsLikes: action.payload, isLoading: false};
        case 'GET_ALLS_LIKES_ERROR':
            return {...state, isLoading: false};

        case 'UPDATE_ALLS_LIKES_REQUEST':
            return {...state};
        case 'UPDATE_ALLS_LIKES_SUCCESS':
            return {...state, allsLikes : state.allsLikes.concat(action.payload), isLoading: false};
        case 'UPDATE_ALLS_LIKES_ERROR':
            return {...state};

        case 'SET_LIKE_REQUEST':
            return {...state, isLoading: true};
        case 'SET_LIKE_SUCCESS':
            return {...state,
                showPopupFreePremium:action.payload.showPopupFreePremium};
                // isLoading: false};
        case 'IS_ALLS_SLUG':
            return {...state, isAlls: action.payload};
        case 'UPDATE_AVATAR_PHOTO_REQUEST':
            return {...state, isLoading: true};
        case 'UPDATE_AVATAR_PHOTO_SUCCESS':
            return {...state, isLoading: false};
        case 'UPDATE_AVATAR_PHOTO_ERROR':
            return {...state, isLoading: false};
        case 'SORT_RATING_NEWS_PHOTOS_REQUEST':
            return {...state, isLoading: true};
        case 'SORT_RATING_NEWS_PHOTOS_SUCCESS':
            return {...state, ratingPhotos:action.payload ,isLoading: false};
        case 'HISTORY_SEND_REQUEST':
            return {...state, isLoading: true};
        case 'HISTORY_SEND_CANCEL':
            return {...state, isLoading: false};
        case 'HISTORY_SEND_SUCCESS':
            return {...state,
                userProfilePhotos: action.payload,
                isLoading: false,
                userHistorySendSuccess: true,
            };
        case 'HISTORY_SEND_ERROR':
            return {...state,
                userHistorySendError: true,
                isLoading: false};

        case 'HISTORY_MODAL_CLOSE':
            return {...state,
                userHistorySendSuccess: false,
                userHistorySendError: false
            };

        case 'SAVE_CURRENT_PHOTO':
            return {...state, currentPhotoid: action.payload};


        case 'DELETE_PHOTO_REQUEST':
            return {...state, isLoading: true};
        case 'DELETE_PHOTO_SUCCESS':
            return {...state, ratingPhotos: action.payload, isLoading: false};
        case 'DELETE_PHOTO_ERROR':
            return {...state, isLoading: false};
        case 'CLOSE_SHOW_POPUP_FREE_PREMIUM':
            return {...state, showPopupFreePremium: false};

        default:
            return state
    }
}
