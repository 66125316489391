import React from 'react';
import './Profile.css';
import FavotityBar from "../components/FavotityBar";
import Title from './../components/Title';
import AddPhoto from "../components/AddPhoto";
import { connect } from 'react-redux'
import {getUserPhotosFromBackend} from "../actions/PhotosActions";
import {getUserFromBackend} from "../actions/UserActions";
import { Redirect } from "react-router-dom"
import { Spinner } from '@vkontakte/vkui';
import UserProfilePhotos from './../components/UserProfilePhotos';
import pluralize from '../plugins/pluralize';
import {isIOS} from 'react-device-detect';

class Profile extends React.Component {
    componentDidMount() {
        if (this.props.user.isFirstTime === false) {
            this.props.getUserFromBackendAction()
            this.props.getUserPhotosFromBackendAction('/');
            window.scrollTo(0,0);
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.props.user.ownUserProfile !== nextProps.user.ownUserProfile) {
            this.props.getUserPhotosFromBackendAction('/');
        }
    }

    handlerGo = (url) => {
        this.props.history.push(url);
    };

    render() {
        const { userProfilePhotos, counts } = this.props.photos;
        const { photo_100, first_name, city, sex } = this.props.user.ownUserProfile;
        const {isFirstTime,userFromBackend} = this.props.user;
        let urlAvatar = {
            'background': 'url("' + photo_100 + '")',
            'backgroundSize': 'cover',
        };
        let cityName;
        if(city instanceof Object) {
            cityName = city.title;
        }
        switch (isFirstTime) {
            case true:
                return <Redirect to="/start" />
            case false:
                if (this.props.user.isLoading === true ||
                    this.props.photos.isLoading === true ||
                this.props.photos.userProfilePhotos === null ) {
                    return(
                        <div className="App">
                            <div className="App-header">
                                <Spinner size="large" style={{color: '#FF3E3E'}}/>
                            </div>
                        </div>
                    )
                }else {
                    return (
                        <div className="PaddingToTop PaddingToBottom">
                            <div className="ProfileData" >
                                <div className="MiddleAvatar" style={urlAvatar}></div>
                                <div className="ProfileWrapperUserData">
                                    <div className="ProfileNameUser">
                                        { first_name }
                                    </div>
                                    <div className="ProfileAgeCityUser">
                                        {
                                            (userFromBackend.age !== null && userFromBackend.age !== undefined) ?
                                                userFromBackend.age + ' ' + pluralize(userFromBackend.age, ['год', 'года', 'лет']): ''
                                        }
                                        {
                                            userFromBackend.age !== null &&
                                            userFromBackend.age !== undefined &&
                                            cityName !== undefined &&
                                            cityName !== null &&
                                            cityName !== ''
                                                ? ', ': ''
                                        }
                                        {
                                            cityName !== undefined &&
                                            cityName !== null &&
                                            cityName !== ''
                                            ? `г. ` + cityName : ''
                                        }
                                    </div>
                                    <div className="ProfileSettings" onClick={() => this.handlerGo('/settings')}>
                                        Настройки
                                    </div>
                                </div>
                            </div>
                            <div className="Profile__FavoriteBars">
                                <FavotityBar name="Взаимные симпатии"
                                             action={() => this.handlerGo('/alls-likes/')}
                                             count={counts.isAlls ? counts.isAlls : 0} />
                                <FavotityBar name={'Ваши фаворит' + (sex === 2 ? 'ки' : 'ы') }
                                             action={() => this.handlerGo('/my-favorites/')}
                                             count={counts.myFavorites  ? counts.myFavorites  : 0} />
                                <FavotityBar name={'Вы лайкнули'}
                                             action={() => this.handlerGo('/i-like/')}
                                             count={counts.iLike ? counts.iLike : 0} />
                                <FavotityBar name={'Вас лайкнули' }
                                             action={() => this.handlerGo('/you-like/')}
                                             count={counts.youLike ? counts.youLike : 0} />
                                {
                                    isIOS ? '' :
                                        <FavotityBar name="Подарки"
                                                     action={() => this.handlerGo('/presents/iPresent')}
                                                     count={counts.presents ? counts.presents : 0} />
                                }
                            </div>
                            <AddPhoto/>
                            <div className="ProfilePhotoInTop">
                                <Title name="Фотографии в ТОПах"/>
                            </div>
                            <div className="WrapperProfile">
                            </div>
                            {
                                userProfilePhotos.length > 0 ?
                                    <UserProfilePhotos userProfilePhotos={userProfilePhotos}/> :
                                    <h3>Фотографий нет</h3>
                            }
                        </div>
                    );
                }
            default:
                return(
                    <div className="App">
                        <div className="App-header">
                            <Spinner size="large" style={{color: '#FF3E3E'}}/>
                        </div>
                    </div>
                )
        }
    }
}

const mapStateToProps = store => {
    return {
        photos: store.photos,
        user: store.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserPhotosFromBackendAction: (url) => {
            dispatch(getUserPhotosFromBackend(url))
        },
        getUserFromBackendAction: () => {
            dispatch(getUserFromBackend())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile)
