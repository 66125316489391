import React from 'react';
import './Cabinet.css';
import CabinetFooter from './../../components/CabinetFooter';
import CabinetHeader from './../../components/CabinetHeader';

const Cabinet = ({ children, ...props }) => {
    return (
        <div className="Cabinet">
            <div className="Header">
                <CabinetHeader {...props} />
            </div>
            <div className="Content">
                {children}
            </div>
            <div className="Footer">
                <CabinetFooter {...props}/>
            </div>
        </div>
    )
}

export default Cabinet;