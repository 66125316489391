import React, {Component} from 'react';
import './SendPresent.css';
import SmallButton from './../components/SmallButton';
import { connect } from 'react-redux'
import {getAllPresents, sendPresent} from "../actions/PresentsActions";
import PropTypes from 'prop-types';
import { Spinner } from '@vkontakte/vkui';
import {saveCurrentPhoto} from "../actions/PhotosActions";

/**
 * Компонент для отображения списка подарков
 * @param props
 * @returns
 * @constructor
 */
const PresentList = (props) => {
    const {presents, anotherUserId, isVip, handlerPresentList, disabled} = props;

    return (
        presents.map( (present) => {
            return (
                <div className="SendPresentWrapper" key={present.id}>
                    <img className="SendPresentIcon" src={present.url} alt="Это букет"/>
                    <div className="SendPresentTitle">
                        <div className="SendPresentName">
                            <span>{present.presentName}</span>
                        </div>
                        <SmallButton
                            disabled={disabled}
                            action={ () => handlerPresentList(present.id, anotherUserId)}
                            name={"Подарить за " +
                        (isVip === true ? Math.ceil(present.price * 0.7) : Math.ceil(present.price)) + " р."} />
                    </div>
                </div>
                )
        })
    )
};

class Presents extends Component {
    componentDidMount() {
        this.props.getAllPresentsAction()
        window.scrollTo(0,0);
        this.props.saveCurrentPhotoAction(this.props.location.state.photoId);
    }

    render() {
        /**
         * Хендлер для отправки подарка пользователю
         * @param int presentId
         * @param int anotherUserId
         */
        const handlerSendPresend =(presentId, anotherUserId) => {
            this.props.sendPresentAction(presentId, anotherUserId);
        }

        const { first_name, id, isVip } = this.props.location.state;
        const { all, isLoading } = this.props.presents;
        if (isLoading) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }

        return (
            <div className="PaddingToTop PaddingToBottom">
                <div className="SendPresent">
                    <div className="SendPresentBanner">
                        {first_name} увидит Ваш подарок и сможет бесплатно перейти в Ваш профиль ВК.<br/><br/>
                    </div>
                    <PresentList
                        disabled={this.props.presents.disabledButtonPresent}
                        presents={all}
                        anotherUserId={id}
                        handlerPresentList = { (presentId,anotherUserId) => handlerSendPresend(presentId, anotherUserId)}
                        isVip = {isVip}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        presents: store.presents,
        user: store.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllPresentsAction: () => {
            dispatch(getAllPresents())
        },
        sendPresentAction: (presentId, anotherUserId) => {
            dispatch(sendPresent(presentId, anotherUserId))
        },
        saveCurrentPhotoAction: (photoId) => {
            dispatch(saveCurrentPhoto(photoId))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Presents)

Presents.propTypes = {
    anotherUserProfile: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    }),
    all: PropTypes.array,
    isLoading: PropTypes.bool,
    isVip: PropTypes.bool,
};
PresentList.propTypes = {
    presents: PropTypes.array.isRequired,
    anotherUserId: PropTypes.number.isRequired,
    isVip:PropTypes.bool
};