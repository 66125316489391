import React from 'react';
import './Title.css';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import 'moment/locale/ru';

function UnixTimeTitle(props) {
    const {unixTime, name} = props;
    return (
        <div className="Title">
            {name}
            <Moment locale="ru" format={"DD MMM YYYY"} unix>
                {unixTime}
            </Moment>
        </div>
    );
}

export default UnixTimeTitle;

UnixTimeTitle.propTypes = {
    name: PropTypes.string.isRequired,
    unixTime: PropTypes.number.isRequired,
};