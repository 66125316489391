import React, {Component} from 'react';
import SmallHeart from "../assets/icon/small-heart.svg";
import './LavkaLikes.css';
import {getLavkaLikes,payLavkaLikes} from "../actions/UserActions";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Spinner } from '@vkontakte/vkui';

class LavkaItem extends Component {
    render() {
        const {lavkaLikes, action, disabled} = this.props;
        return lavkaLikes.map( lavkalike => {
            return (
                <button className="LargeButton" disabled={disabled} onClick={() => action(lavkalike.id)}
                key={lavkalike.id}
                >
                    <span>{lavkalike.plusHeart + " лайков за " +
                    lavkalike.price + " руб. "}</span><span className="LineThrough">{lavkalike.oldPrice ? lavkalike.oldPrice + " руб." : ''}</span>
                </button>
            );
        });
    }
};

class LavkaLikes extends Component {
    componentDidMount() {
        this.props.getLavkaLikesAction();
    }


    handlePayLavkaLikes = (lavkalikeId) => {
        this.props.payLavkaLikesAction(lavkalikeId);
    }
    render() {
        if (this.props.user.isLoading  ||
        this.props.user.lavkaLikes === null) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }else {
            const {lavkaLikes} = this.props.user;
            return (
                <div className="PaddingToTop">
                    <div className="LavkaWrapper">
                        <div className="LavkaLikesTitle">
                            <span>Здесь Вы можете увеличить лайки</span>
                            <img src={ SmallHeart } alt="Количество лайков"/>
                        </div>
                        {
                            lavkaLikes.length !== 0 ?
                                <LavkaItem
                                    disabled={this.props.user.disabledButtonLavkaLike}
                                    action={(lavkalikeId) => this.handlePayLavkaLikes(lavkalikeId)}
                                    lavkaLikes={lavkaLikes}/>
                                    : ''
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLavkaLikesAction: () => {
            dispatch(getLavkaLikes())
        },
        payLavkaLikesAction: (lavkalikeId) => {
            dispatch(payLavkaLikes(lavkalikeId))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LavkaLikes)

LavkaItem.propTypes = {
    lavkaLikes: PropTypes.array.isRequired,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};