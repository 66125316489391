import React, {Component} from 'react';
import FilterSelect from '../components/FilterSelect'
import './Filter.css';
import LargeButton from './../components/LargeButton';
import {getAllCountries, getAllCities, getAllSchools,
    getAllUniversities, getAllWorks, getAgeAndRelation,
    getCurrentUserFilter,getFilterData,resetFilter } from "../actions/FilterActions";
import {saveFilter} from "../actions/FilterActions";
import {connect} from 'react-redux';
import {getUserFromVk} from "../actions/UserActions";
import { Range, getTrackBackground } from 'react-range';
import SubTitle from "../components/SubTitle";
import Switch from "../components/Switch";

const STEP = 1;
const MIN = 18;
const MAX = 99;

class Filter extends Component{

    constructor(props) {
        super(props);

        let isMale = this.props.filter.isMale;
        let gender = this.props.user.userFromBackend.gender;
        if (isMale !== null) {
            if(isMale.label === 2) {
                isMale = true;
                }else {
                isMale = false;
                }
            }else {
            if(gender === 2) {
                isMale = false;
                }else {
                isMale = true;
            }
        }

        this.state = {
            country: this.props.filter.country,
            city: this.props.filter.city,
            school: this.props.filter.school,
            university: this.props.filter.university,
            company: this.props.filter.company,
            position: this.props.filter.position,
            relation: this.props.filter.relation,
            isMale: isMale,
            values: [
                this.props.filter.minAge ? this.props.filter.minAge.label : 20,
                this.props.filter.maxAge ? this.props.filter.maxAge.label : 40
            ]
        }
    }

    componentDidMount() {
        this.props.getFilterDataAction();
        window.scrollTo(0,0);
        if (this.state.country !== null && this.state.country !== undefined) {
            if ('value' in this.state.country) {
                this.props.getAllCitiesAction(this.state.country.value);
            }
        }
    }

    handlesaveFilter = () => {
        const {country, city, school, university, company, position, relation, values, isMale} = this.state;
        if(!(country === null && city === null && school === null && relation === null &&
            university === null && company === null && position === null && values === null && isMale === null)) {
            this.props.saveFilterAction(
                country,
                city,
                school,
                university,
                company,
                position,
                relation,
                isMale ? {label: 2, value: 2} : {label: 1, value: 1},
                {label: values[0], value: values[0]},
                {label: values[1], value: values[1]},
            );
            this.props.history.goBack();
        }
    };
    handleresetFilter = () => {
        this.props.resetFilterAction();
        this.setState({ city: null });
        this.setState({ country: null });
        this.setState({ values: [20,40] });

        let gender = this.props.user.userFromBackend.gender;
            if(gender === 2) {
                this.setState({ isMale: false });
                }else {
                this.setState({ isMale: true });
                }
    };

    handleChangeCountry = (country) => {
         this.setState({ city: null });
         this.setState({ country: country});
         if (country !== null) {
             if ('value' in country) {
                 this.props.getAllCitiesAction(country.value);
             }
         }
    };
    handleChangeCity = (city) => {
        this.setState({ city });
    };
    handleChangeSchool = (school) => {
        this.setState({ school });
    };
    handleChangeUniversity = (university) => {
        this.setState({ university });
    };
    handleChangeCompany = (company) => {
        this.setState({ company });
    };
    handleChangePosition = (position) => {
        this.setState({ position });
    };
    handleChangeRelation = (relation) => {
        this.setState({ relation });
    };

    handlerChangeGender = () => {
        this.setState(prevState => ({
            isMale: !prevState.isMale,
            }));
        };

    render() {
        const {country, city, values, isMale} = this.state;

        return (
            <div className="PaddingToTop Filter__Padding">
                <div className="SectionSelect">
                    <div className="WrapperSelect">
                        <FilterSelect placeholder="Страна"
                                      options={this.props.filter.countries}
                                      handleChange={this.handleChangeCountry}
                                      selectedOption={country}
                                      noFoundMessage="Страна не найдена"
                        />
                    </div>
                    <div className="WrapperSelect">
                        <FilterSelect placeholder="Город"
                                      options={country ? this.props.filter.cities : []}
                                      handleChange={this.handleChangeCity}
                                      selectedOption={city}
                                      noFoundMessage="Город не найден"
                                      needSelectCountry="Выберите страну"
                                      isCountrySelect={country !== null ? 0 : 1}
                        />
                    </div>
                </div>
                <div className="SectionSelect">

                    <SubTitle name="Пол:"/>
                    <div className="SetSex__SetGender Padding">
                        <span>Женский</span>
                        <Switch
                            isChecked={isMale}
                            action={this.handlerChangeGender}
                        />
                        <span>Мужской</span>
                    </div>
                </div>
                <div className="SectionSelect">
                    <SubTitle name="Возраст:"/>

                    <div className="WrapperSelectRow">
                        <div className="WrapperSelectRow__Wrapper">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Range
                                    values={values}
                                    step={STEP}
                                    min={MIN}
                                    max={MAX}
                                    onChange={values => {
                                        this.setState({ values })
                                    }}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            onMouseDown={props.onMouseDown}
                                            onTouchStart={props.onTouchStart}
                                            style={{
                                                ...props.style,
                                                height: '36px',
                                                display: 'flex',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: '5px',
                                                    width: '100%',
                                                    borderRadius: '4px',
                                                    background: getTrackBackground({
                                                        values: this.state.values,
                                                        colors: ['#ccc', '#29222A', '#ccc'],
                                                        min: MIN,
                                                        max: MAX
                                                    }),
                                                    alignSelf: 'center'
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '42px',
                                                width: '42px',
                                                borderRadius: '4px',
                                                backgroundColor: '#FFF',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: '0px 2px 6px #AAA'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: '16px',
                                                    width: '5px',
                                                    backgroundColor: isDragged ? '#29222A' : '#CCC'
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                <output style={{ marginTop: '15px' }} id="output">
                                    {this.state.values[0]} - {this.state.values[1]}
                                </output>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="WrapperButton">
                    <LargeButton
                        name="Применить фильтр"
                        action={this.handlesaveFilter}/>
                    <LargeButton
                        name="Сбросить фильтр"
                        action={this.handleresetFilter}
                        style={
                        {
                            "background": "#FF3E3E",
                            "border": "1px solid #FF3E3E",
                        }}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetFilterAction: () => {
            dispatch(resetFilter())
        },
        getFilterDataAction: () => {
            dispatch(getFilterData())
        },
        getAllCountriesAction: () => {
            dispatch(getAllCountries())
        },
        getAllCitiesAction: (countryId) => {
            dispatch(getAllCities(countryId))
        },
        getAllSchoolsAction: () => {
            dispatch(getAllSchools())
        },
        getAllUniversitiesAction: () => {
            dispatch(getAllUniversities())
        },
        getAllWorksAction: () => {
            dispatch(getAllWorks())
        },
        getAgeAndRelationAction: () => {
            dispatch(getAgeAndRelation())
        },
        getCurrentUserFilterAction: () => {
            dispatch(getCurrentUserFilter())
        },
        saveFilterAction: (
            country,
            city,
            school,
            university,
            company,
            position,
            relation,
            isMale,
            minAge,
            maxAge) => {
            dispatch(saveFilter(
                country,
                city,
                school,
                university,
                company,
                position,
                relation,
                isMale,
                minAge,
                maxAge))
        },
        getUserFromVkAction: () => {
            dispatch(getUserFromVk())
        },
    }
};

const mapStateToProps = store => {
    return {
        filter: store.filter,
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter)