import React, {Component} from 'react';
import {getILike, updateILike} from "../actions/PhotosActions";
import { connect } from 'react-redux'
import { Spinner } from '@vkontakte/vkui';
import ImageItem from "../components/ImageItem";

class ILike extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iLike: null,
            offset: 5
        };
    }

    componentDidMount() {
        this.props.getILikeAction(0);
        window.scrollTo(0,0);

        setTimeout(() => {
            this.setState({
                iLike: this.props.photos.iLike
            });
        }, 1000);
    }

    handlerscrollToRefresh = () => {
        const {offset} = this.state;
        this.props.updateILikeAction(offset)
            .then( (response) => {
                if (response) {
                    this.setState({
                        iLike: this.state.iLike.concat(response.data)
                    });
                }
            })
            .then( () => {
                this.setState(prevState => ({
                    offset: prevState.offset + 5,
                }));
            })
            .catch( (error) => {
                console.log(error)
            });

    };

    render() {
        if (this.props.photos.isLoading === true ||
            this.props.user.isLoading === true ||
            this.state.iLike === null
        ) {
            return(
                <div className="App">
                    <div className="App-header">
                        <Spinner size="large" style={{color: '#FF3E3E'}}/>
                    </div>
                </div>
            )
        }else {
            const {iLike} = this.props.photos;
            return (
                <div className={iLike.length === 1 || iLike.length === 0 ? "Fixed" : "WrapperPremium"}
                >
                    <div className="PaddingToTop PaddingToBottom">
                        {
                            iLike.length !== 0 ? <ImageItem
                                    items={iLike}
                                    scrollToRefresh={() => this.handlerscrollToRefresh} /> :
                                <h3>Вы ещё никого не лайкнули</h3>
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getILikeAction: (offset) => {
            dispatch(getILike(offset))
        },
        updateILikeAction: (offset) => {
            return dispatch(updateILike(offset))
        },
    }
};

const mapStateToProps = store => {
    return {
        photos: store.photos,
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ILike)