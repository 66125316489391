import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import './ImageItem.css';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom'
import pluralize from "../plugins/pluralize";
import './../pages/Rating.css';

const ImageItem = (props) => {
    const {items, scrollToRefresh} = props;

    return (
        <InfiniteScroll
            dataLength={items.length}
            next={scrollToRefresh()}
            hasMore={true}
            loader={<h4></h4>}
        >
            {
                items.map( item => {
                    return (
                        <div className="AvatarItemWithPhoto" key={item.id}>
                            <div className="AvatarItemWithPhoto__Avatar">
                                <Link className="AvatarWrapper" to={'/' + item.id} >
                                    <div className="SmallAvatar BackgroundSize" style={{
                                        'background': 'url(' + item.photo_100 +')'
                                    }}></div>
                                    <div className="StatusWrapper">
                        <span className="NameUser">
                            {item.first_name}
                        </span>
                                        <span className="AgeUser">
                                {
                                    (item.age !== null && item.age !== undefined) ?
                                        item.age + ' ' +
                                        pluralize(item.age, ['год', 'года', 'лет']): ''
                                }
                        </span>
                                    </div>
                                </Link>
                            </div>
                            <div className="ImageItem__AvatarItemWithPhoto__Img">
                                <LazyLoad offsetVertical={300} >
                                    <img className="TopImage"
                                         src={item.url}
                                         height={item.height}
                                         width={item.width} alt=""/>
                                </LazyLoad>
                            </div>
                        </div>
                    );
                })
            }
        </InfiniteScroll>
    );
}

export default ImageItem

ImageItem.propTypes = {
    items: PropTypes.array.isRequired
};