import React from 'react';
import './StartInfo.css';
import Title from './../components/Title';
import SubTitle from './../components/SubTitle';
import { connect } from 'react-redux'
import {setUser} from "../actions/UserActions";
import Switch from "../components/Switch";
import { Range, getTrackBackground } from 'react-range';
import LargeButton from "../components/LargeButton";

const STEP = 1;
const MIN = 18;
const MAX = 99;

class SaveGenderAndAge extends React.Component {
    constructor(props) {
        super(props);
        const {sex, bdate} = this.props.user.ownUserProfile;
        let isMale = false;

        if (sex === 2) {
            isMale = true;
        }
        let age = 20;

        if (bdate !== null && bdate !== undefined) {
            //Если возраст полный(то есть 3 точки)
            if (bdate.split('.').length - 1 === 2) {
                // Если возраст меньше 100
                if (new Date().getFullYear() - Number(bdate.split('.')[2]) < 100) {
                    // С возрастом всё ок
                    age = new Date().getFullYear() - Number(bdate.split('.')[2]);
                }
            }
        }

        this.state = {
            isMale: isMale,
            values: [age]
        }
    }

    handlerSetGender = () => {
        this.setState(prevState => ({
            isMale: !prevState.isMale,
        }));
    };

    handlerSaveUser = () => {
        const {isMale, values} = this.state;
        this.props.setUserAction(isMale, values[0]);
        this.props.history.replace('/');
    };

    render() {
        const {isMale, values} = this.state;
        return (
            <div className="StartInfo">
                <Title name="Пожалуйста, подтвердите"
                       font={18} weight={100}/>
                <Title name="Ваши данные,"
                       font={18} weight={100}/>
                <Title name="полученные из ВК."
                       font={18} weight={100}/>

               <div className="SaveGender__Wrapper">
                   <SubTitle name="Ваш пол:"/>
                   <div className="SetSex__SetGender Padding">
                       <span>Женский</span>
                       <Switch
                           isChecked={isMale}
                           action={this.handlerSetGender}
                       />
                       <span>Мужской</span>
                   </div>
               </div>
                <div className="SaveAge__Wrapper">
                    <SubTitle name="Ваш возраст:"/>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                paddingTop: '10px'
                            }}
                        >
                            <Range
                                values={values}
                                step={STEP}
                                min={MIN}
                                max={MAX}
                                onChange={values => this.setState({ values })}
                                renderTrack={({ props, children }) => (
                                    <div
                                        onMouseDown={props.onMouseDown}
                                        onTouchStart={props.onTouchStart}
                                        style={{
                                            ...props.style,
                                            height: '36px',
                                            display: 'flex',
                                            width: '100%'
                                        }}
                                    >
                                        <div
                                            ref={props.ref}
                                            style={{
                                                height: '5px',
                                                width: '100%',
                                                borderRadius: '4px',
                                                background: getTrackBackground({
                                                    values: this.state.values,
                                                    colors: ['#29222A', '#ccc'],
                                                    min: MIN,
                                                    max: MAX
                                                }),
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                )}
                                renderThumb={({ props, isDragged }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '42px',
                                            width: '42px',
                                            borderRadius: '4px',
                                            backgroundColor: '#FFF',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: '0px 2px 6px #AAA'
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '16px',
                                                width: '5px',
                                                backgroundColor: isDragged ?
                                                    '#29222A' : '#CCC'
                                            }}
                                        />
                                    </div>
                                )}
                            />
                            <output style={{ marginTop: '15px' }} id="output">
                                {this.state.values[0]}
                            </output>
                        </div>
                    </div>
                </div>

                <LargeButton name="Подтвердите"
                                     action={this.handlerSaveUser}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserAction: (isMale, age) => {
            dispatch(setUser(isMale, age))
        },
    }
};

const mapStateToProps = store => {
    return {
        user: store.user,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveGenderAndAge)