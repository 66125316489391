import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from '../reducers'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

export const store = createStore(
    rootReducer(history),
    applyMiddleware(
        routerMiddleware(history),
        thunk,
        logger
    ));
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(rootReducer, composeEnhancers(
//     applyMiddleware(thunk, logger)
// ));