import API from '../plugins/axios'
import { Base64 } from 'js-base64';
import VKConnectPromise from '@vkontakte/vkui-connect-promise';
// import VKConnect from '@vkontakte/vkui-connect-mock';

const GET_ALL_PRESENTS_REQUEST = 'GET_ALL_PRESENTS_REQUEST';
const GET_ALL_PRESENTS_SUCCESS = 'GET_ALL_PRESENTS_SUCCESS';
const GET_OWN_PRESENTS_REQUEST = 'GET_OWN_PRESENTS_REQUEST';
const GET_OWN_PRESENTS_SUCCESS = 'GET_OWN_PRESENTS_SUCCESS';

const DISABLED_BUTTON_PRESENT = 'DISABLED_BUTTON_PRESENT';
const ENABLED_BUTTON_PRESENT = 'ENABLED_BUTTON_PRESENT';

/**
 * Экшн для получения списка всех подарков для вывода на странице send-present
 * @returns {Function}
 */
export function getAllPresents() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_ALL_PRESENTS_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/present/get-presents',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: GET_ALL_PRESENTS_SUCCESS,
                payload: response.data,
            });
        }).catch(function (error) {
                console.log(error)
            });
    }
}

/**
 * Экшн для отправки подарка к пользователю
 * @param presentId
 * @param anotherUserId
 * @returns {Function}
 */
export function sendPresent(presentId, anotherUserId) {
    return (dispatch,getState) => {
        dispatch({
            type: DISABLED_BUTTON_PRESENT,
        });
        let sign = getState().user.sign;
        API({
            url:'/order/create-order',
            method: 'post',
            data: {
                'sign': sign,
                'entity_name': 'Present',
                'entity_id': presentId,
            },
        }).then(function (response) {
            var order_id = response.data.order_id;
            var amount = response.data.amount;
            var ts = + new Date();
            var merchant_data = Base64.encode(JSON.stringify({"amount":amount,"currency":"RUB","order_id":order_id,"ts":ts}));

            API({
                url:'/order/get-vk-pay-sign',
                method: 'post',
                data: {
                    'sign': sign,
                    'data': merchant_data,
                },
            }).then(function (response) {

                merchant_data = response.data.merchant_data;
                var merchant_sign = response.data.merchant_sign;
                var description = ' описание';
                var app_data = 'amount='+amount+
                    'data={"another_user_id":'+anotherUserId+',"currency":"RUB","merchant_data":"'+merchant_data+
                    '","merchant_sign":"'+merchant_sign+'","order_id":"'+ order_id+'","ts":'+ts+'}description='+description+
                    'merchant_id='+process.env.REACT_APP_MERCHANT_ID+'version=2';

                API({
                    url:'/order/get-vk-pay-app-sign',
                    method: 'post',
                    data: {
                        'data': app_data,
                        'sign': sign,
                    },
                }).then(function (response) {
                    VKConnectPromise.send("VKWebAppOpenPayForm",
                        {
                            "app_id": Number(process.env.REACT_APP_VK_APP_ID), "action": "pay-to-service", "params": {
                                "amount": amount,
                                "description": description,
                                "action": "pay-to-service",
                                "merchant_id": process.env.REACT_APP_MERCHANT_ID,
                                "version": 2,
                                "sign": response.data.app_sign,
                                "data": {
                                    "another_user_id": anotherUserId,
                                    "currency": "RUB",
                                    "merchant_data": merchant_data,
                                    "merchant_sign": merchant_sign,
                                    "order_id": order_id,
                                    "ts": ts,
                                }
                            }
                        }).then(function () {
                        //При успехе разблокируем кнопку
                        dispatch({
                            type: ENABLED_BUTTON_PRESENT,
                        });
                        }).catch(function () {
                        //При ошибке разблокируем кнопку
                        dispatch({
                            type: ENABLED_BUTTON_PRESENT,
                        });
                    });
                })
            })

        }).catch(function (error) {
                // console.log(error)
            });
    }
}

export function getOwnPresents() {
    return (dispatch, getState) => {
        dispatch({
            type: GET_OWN_PRESENTS_REQUEST
        });
        let sign = getState().user.sign;
        API({
            url:'/present/get-own-presents',
            method: 'post',
            data: {
                'sign': sign
            },
        }).then(function (response) {
            dispatch({
                type: GET_OWN_PRESENTS_SUCCESS,
                payload: response.data,
            });
        })
            .catch(function (error) {
                console.log(error)
            });
    }
    
}