import React from 'react';
import './Checkbox.css';
import PropTypes from 'prop-types';

function Checkbox(props) {
    const { name, checked, action, link, afterName} = props;

    return (
        <ul className="Checkbox">
            <li className="CheckboxGroup">
                <label htmlFor={"checkbox-" + name} className="Label">{ name }
                    <a className="UnderLink" href={'/dogovor.html'}
                       target="_blank"
                       rel="noopener noreferrer" >
                        {link}
                    </a>
                    {afterName}
                    <input type="checkbox"
                           id={"checkbox-" + name}
                           className="CheckboxInput"
                           onChange={action}
                           checked={checked}/>
                    <span className="Checkmark"></span>
                </label>
            </li>
        </ul>
    );
}

export default Checkbox

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    checked:  PropTypes.bool.isRequired,
    link: PropTypes.string,
    afterName: PropTypes.string
}