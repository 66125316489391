import React from 'react';
import PropTypes from 'prop-types';
import './SmallButton.css';

function SmallButton(props) {
    const {action, name, disabled} = props;
    return (
        <button className="SmallButton"
                onClick={action}
                disabled={disabled}>
            <div className="TextColor">
                {name}
            </div>
        </button>
    );
}

export default SmallButton;

SmallButton.propTypes = {
    action: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
}